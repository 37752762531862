<div class="row">
  <div class="col-md-12 p-r-32 p-l-32">
    <ng-container>
      <div class="step-one boxshadow-form">
        <!--Header Block starts here Designed by Krishna Kanth on 18th Jan 2024-->
        <div class="title text-center p-t-20 d-flex justify-content-center">
          <p class="marroncolor f-w-500 m-t-24 f-s-24 m-t-24 d-flex align-items-center">J. General History</p>
          <label for="digitalsigninput" class="uploadedimages">
            <img class="uploadedimage" style="width: 100px;" *ngIf="studentPhotoImageUrl" [src]="studentPhotoImageUrl">
            <div *ngIf="!studentPhotoImageUrl">
            <img mat-card-image src="/assets/images/svgs/upload-image.svg" alt="Upload Icon" />
            </div>
            </label>
        </div>
        <!--Header Block starts here Designed by Krishna Kanth on 18th Jan 2024-->

        <mat-card-content>
          <form [formGroup]="stepForm" (ngSubmit)="onSubmit()">
            <div id="scrollbar" class="internalform">
              <div class="row m-t-40">
                <div class="col-md-12 m-t-40">
                  <mat-label class="formlabel">Do you have Concerns with any of the following with respect to the Student? (Tick all that applicable)</mat-label>
                  <section>
                    <div class="row" >
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Behaviour')" [disabled]="disableAllExceptNoConcerns"(change)="dissatisfied($event, 'Behaviour')">Behaviour</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Development')" [disabled]="disableAllExceptNoConcerns"(change)="dissatisfied($event, 'Development')">Development</mat-checkbox></div>
                      <div class="col-md-4">   <mat-checkbox [checked]="isCheckboxChecked('Friends')"[disabled]="disableAllExceptNoConcerns" (change)="dissatisfied($event, 'Friends')">Friends</mat-checkbox></div>

                    </div>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('General health')"[disabled]="disableAllExceptNoConcerns" (change)="dissatisfied($event, 'General health')">General Health</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Growth')" [disabled]="disableAllExceptNoConcerns"(change)="dissatisfied($event, 'Growth')">Growth</mat-checkbox></div>
                      <div class="col-md-4">  <mat-checkbox [checked]="isCheckboxChecked('Habits')"[disabled]="disableAllExceptNoConcerns" (change)="dissatisfied($event, 'Habits')">Habits</mat-checkbox></div>


                    </div>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('No significant concerns')" (change)="dissatisfied($event, 'No significant concerns')">No significant concerns</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Social activity')" [disabled]="disableAllExceptNoConcerns" (change)="dissatisfied($event, 'Social activity')">Social Activity</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('School performance')"[disabled]="disableAllExceptNoConcerns" (change)="dissatisfied($event, 'School performance')">School Performance</mat-checkbox></div>
                    </div>
                  </section>
                </div>

                <div class="col-md-12 m-t-20">
                  <mat-label class="formlabel">How would you rate Student's overall Physical Health?</mat-label>
                  <div class="col-md-6 m-t-30 m-l-12">
                    <div class="sliderLabel">
                    <span>{{ sliderLabel }}</span>
                    </div>
                    <mat-slider class="mat-slider" [min]="0" [max]="10" discrete="true" (input)="updateSliderValue($event)">
                      <input matSliderThumb formControlName="How_would_you_rate_the_student_overall_physical_health">
                    </mat-slider>
                    
                    
                  </div>
                  <div class="number-container row col-md-12 m-t-10 m-l-12">
                    <div *ngFor="let number of sliderScaleValues" class="subh" >{{ number }}</div>
                  </div>
                  <div class="row d-flex">
                    <div class="col-md-6 m-t-20">
                    <mat-label class="m-t-64 m-l-40">Very Poor</mat-label>
                  </div>
                  <div class="col-md-6 m-t-20">
                    <mat-label class="m-t-64 m-l-40">Excellent</mat-label>
                  </div>
                    </div>
                </div>
                <div class="col-md-12 m-t-20">
                  <mat-label class="formlabel">Is the Student’s school performance significantly affected by any of the following?</mat-label>
                  <section>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckbox('Emotional difficulties')" (change)="performance($event, 'Emotional difficulties')">Emotional Difficulties</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckbox('Domestic discord')" (change)="performance($event, 'Domestic discord')">Domestic Discord</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckbox('Learning disabilities')" (change)="performance($event, 'Learning disabilities')">Learning Disabilities</mat-checkbox></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckbox('Physical health')" (change)="performance($event, 'Physical health')">Physical Health</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckbox('Social activity')" (change)="performance($event, 'Social activity')">Social Activity</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckbox('Addiction')" (change)="performance($event, 'Addiction')">Addiction</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckbox('Separation/Divorce of parents')" (change)="performance($event, 'Separation/Divorce of parents')">Separation/Divorce of parents</mat-checkbox></div>
                    </div>
                  </section>
                </div>

                <div class="col-md-12 m-t-20">
                  <mat-label class="formlabel">Is the Student's school performance significantly dissatisfied by any of the following?</mat-label>
                  <section>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxCheck('Appearance')" (change)="numberssaved($event, 'Appearance')">Apperance</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxCheck('Ability to communicate')" (change)="numberssaved($event, 'Ability to communicate')">Ability to communicate</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxCheck('Family life')" (change)="numberssaved($event, 'Family life')">Family Life</mat-checkbox></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxCheck('Friendships')" (change)="numberssaved($event, 'Friendships')">Friendships</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxCheck('Physical activities')" (change)="numberssaved($event, 'Physical activities')">Physical Activities</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxCheck('Social status')" (change)="numberssaved($event, 'Social status')">Social Status</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxCheck('School performance')" (change)="numberssaved($event, 'School performance')">School Performance</mat-checkbox></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <mat-checkbox [checked]="isCheckboxCheck('Other')" (change)="numberssaved($event, 'Other')">Other</mat-checkbox>
                    </div>
                  </div>
                  <div class="row" *ngIf="showOtherInput">
                    <div class="col-md-12">
                      <mat-form-field appearance="outline" class="w-100">
                        <input matInput placeholder="If others, type here" formControlName="Student_dissatisfied_Other">
                        <mat-error class="error-message" *ngIf="stepForm.get('Student_dissatisfied_Other').invalid && stepForm.get('Student_dissatisfied_Other')">
                          Other activity is required.
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  </section>
                </div>
              </div>
            </div>
            <div class="row justify-content-end m-t-32 float-right">
              <div class="col-lg-12 p-r-64">
                <app-previousbutton></app-previousbutton>
                <app-nextbutton></app-nextbutton>
              </div>
            </div>
          </form>
        </mat-card-content>
      </div>
    </ng-container>
  </div>
</div>
