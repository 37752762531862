import { Component, VERSION } from '@angular/core';
import {  Input, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { FormService } from '../../form.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MaterialModule } from 'src/app/material.module';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-final-preview',
  templateUrl: './final-preview.component.html',
  styleUrls: ['./final-preview.component.scss'],
  providers: [FormGroupDirective]  // Add this line

})
export class FinalPreviewComponent {
  name = 'Angular ' + VERSION.major;
  @Input() stepForm!: FormGroup;
  formService: any;
  userId: string;
  Student_FirstName: any;
  Student_DOB: any;
  class_name: any;
  UIN: any;
  Age: any;
  Gender: any;
  qrcode_image: any;
  upload_photo: any;
  StudentFirstName: any;
  StudentDOB: any;
  classname: any;
  uin: any;
  age: any;
  gender: any;
  Registered_EmailId: any;
  Primary_Contact: any;
  Upload_Your_Photo: any;
  Hcp_qrcode: any;


  constructor(
    private rootFormGroup: FormGroupDirective,
    private formservice: FormService ,
    private fb: FormBuilder,
    private routes:Router
  ){}

  //Download Document

 public captureScreen() {
  var data = document.getElementById('downloadDocument');
  var pdf = new jsPDF('p', 'mm', 'a4');
  var options = {
    scale: 2,
    useCors:true,
  };

  html2canvas(data, options).then(canvas => {
    var imgWidth = 210;
    var pageHeight = 297;
    var imgHeight = (canvas.height * imgWidth) / canvas.width;
    var heightLeft = imgHeight;
    var position = 0;
    var imgData = canvas.toDataURL('image/png');

    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft > 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    pdf.save('application.pdf');
  });
}


  //Download ID Card
 public async downloadIdCard() {
  const data = document.getElementById('downloadIdCard');
  if (!data) {
    console.error('Element not found');
    return;
  }

  const options = {
    allowTaint: true,
    useCORS: true,
    logging: true,
    scale: 2
  };

  try {
    const canvas = await html2canvas(data, options);
    const imgWidth = 120;
    const imgHeight = 80;

    const contentDataURL = canvas.toDataURL('image/png');
    const pdf = new jsPDF('l', 'mm', [imgWidth, imgHeight]);

    const position = 0;

    pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
    console.log('Added canvas image to PDF');

    const profilePictureSize = 30;
    const profilePictureUrl = this.stepForm.get('upload_photo').value;
    if (profilePictureUrl) {
      // Draw white circle to clip profile picture into a round shape
      const profileX = 17;
      const profileY = 15;
      const radius = profilePictureSize / 2;

      // Save current state
      pdf.setFillColor(255, 255, 255);
      pdf.circle(profileX + radius, profileY + radius, radius, 'F');

      // Add profile picture
      pdf.addImage(profilePictureUrl, 'PNG', profileX, profileY, profilePictureSize, profilePictureSize, undefined, 'FAST');
      console.log('Added profile picture to PDF');
    }

    const qrCodeSize = 35;
    const qrCodeUrl = this.stepForm.get('qrcode_image').value;
    if (qrCodeUrl) {
      pdf.addImage(qrCodeUrl, 'PNG', 70, 30, qrCodeSize, qrCodeSize);
      console.log('Added QR code image to PDF');
    }

    pdf.save('Infoseek_ID_Card.pdf');
    console.log('PDF saved successfully');
  } catch (error) {
    console.error('Error generating PDF:', error);
  }
}
  
 ngOnInit(): void {
  // this.getidcard()

  this.infoseekidcard()
  this.userId = localStorage.getItem('userId');
  console.log(this.userId, "fghjk");

  // Fetch additional information based on userId
  this.formservice.InfoseekMasterId(this.userId).subscribe(
    (data: any) => {
      const InfoseekMasterId = data.Result[0];
      this.stepForm.patchValue({
        following_information_providedby: InfoseekMasterId['following_information_providedby'],
        If_Other: InfoseekMasterId['If_Other'],
        Student_MiddleName1: InfoseekMasterId['Student_MiddleName1'] || '--',
        Student_MiddleName2: InfoseekMasterId['Student_MiddleName2']|| '--',
        Student_FirstName: InfoseekMasterId['Student_FirstName'] || '--',
        Student_LastName: InfoseekMasterId['Student_LastName'] || '--',
        Student_DOB: InfoseekMasterId['Student_DOB'] || '--',
        class_name: InfoseekMasterId['class_name'] || '--',
        UIN: InfoseekMasterId['UIN'] || '--',
        Age: InfoseekMasterId['class_name'] || '--',
        Gender: InfoseekMasterId['Gender'] || '--',
        Rh_Factor: InfoseekMasterId['Rh_Factor'] || '--',
        Number_Of_Sisters: InfoseekMasterId['Number_Of_Sisters'] || '--',
        Number_Of_Brothers: InfoseekMasterId['Number_Of_Brothers'] || '--',
        Mothers_FirstName: InfoseekMasterId['Mothers_FirstName'] || '--',
        Mothers_MiddleName1: InfoseekMasterId['Mothers_MiddleName1'] || '--',
        Mothers_MiddleName2: InfoseekMasterId['Mothers_MiddleName2'] || '--',
        Mothers_LastName: InfoseekMasterId['Mothers_LastName'] || '--',
        Mothers_Ethnicity: InfoseekMasterId['Mothers_Ethnicity'] || '--',
        Mothers_Ethnicity_If_Other: InfoseekMasterId['Mothers_Ethnicity_If_Other'],
        Fathers_FirstName: InfoseekMasterId['Fathers_FirstName'] || '--',
        Fathers_MiddleName1: InfoseekMasterId['Fathers_MiddleName1'] || '--',
        Fathers_MiddleName2: InfoseekMasterId['Fathers_MiddleName2'] || '--',
        Fathers_LastName: InfoseekMasterId['Fathers_LastName'] || '--',
        Fathers_Ethnicity: InfoseekMasterId['Fathers_Ethnicity'] || '--',
        Fathers_Ethnicity_If_Other: InfoseekMasterId['Fathers_Ethnicity_If_Other'] ,
        Do_you_have_health_insurance: InfoseekMasterId['Do_you_have_health_insurance'] || '--',
        Medical_Aid: InfoseekMasterId['Medical_Aid'] || '--',
        Policy_Card: InfoseekMasterId['Policy_Card'] || '--',
        Expiry_Date: InfoseekMasterId['Expiry_Date'] || '--',
        Building_Name: InfoseekMasterId['Building_Name'] || '--',
        Apartment_Villa_No: InfoseekMasterId['Apartment_Villa_No'] || '--',
        Street_No: InfoseekMasterId['Street_No'] || '--',
        Area: InfoseekMasterId['Area'] || '--',
        City: InfoseekMasterId['City'] || '--',
        Country: InfoseekMasterId['Country'] || '--',
        State: InfoseekMasterId['State'] || '--',
        Zip_Code: InfoseekMasterId['Zip_Code'] || '--',
        Alternate_MobileNumber: InfoseekMasterId['Alternate_MobileNumber'] || '--',
        Belongs_To: InfoseekMasterId['Belongs_To'] || '--',
        Email_Registered_With_School: InfoseekMasterId['Email_Registered_With_School'] || '--',
        Primary_Contact_Belongs_To: InfoseekMasterId['Primary_Contact_Belongs_To'] || '--',
        Primary_Contact_Belongs_To_Other: InfoseekMasterId['Primary_Contact_Belongs_To_Other'],
        Primary_Contact: InfoseekMasterId['Primary_Contact'] || '--',
        Primary_Contact_Full_Name: InfoseekMasterId['Primary_Contact_Full_Name'] || '--',
        Secondary_Contact: InfoseekMasterId['Secondary_Contact'],
        Secondary_Contact_Belongs_To: InfoseekMasterId['Secondary_Contact_Belongs_To'] || '--',
        Secondary_Contact_Belongs_To_Other: InfoseekMasterId['Secondary_Contact_Belongs_To_Other'] ,
        Secondary_Contact_Full_Name: InfoseekMasterId['Secondary_Contact_Full_Name'] || '--',
        Activites_Other: InfoseekMasterId['Activites_Other'] ,
        Do_you_have_pets_at_home: InfoseekMasterId['Do_you_have_pets_at_home'] || '--',
        Isthe_student_memberofasports_teamatschool_Yes: InfoseekMasterId['Isthe_student_memberofasports_teamatschool_Yes'] || '--',
        Isthe_student_memberofasports_teamatschool: InfoseekMasterId['Isthe_student_memberofasports_teamatschool'] || '--',
        Do_you_have_pets_at_home_Yes_Duration: InfoseekMasterId['Do_you_have_pets_at_home_Yes_Duration'] || '--',
        Do_you_have_pets_at_home_Yes_Animal: InfoseekMasterId['Do_you_have_pets_at_home_Yes_Animal'] || '--',
        section_name: InfoseekMasterId['section_name'] || '--',
        Type_of_meal_Other: InfoseekMasterId['Type_of_meal_Other'] ,
        Does_the_student_have_breakfast_regularly: InfoseekMasterId['Does_the_student_have_breakfast_regularly'] || '--',
        Does_the_student_have_regular_meal_at_school: InfoseekMasterId['Does_the_student_have_regular_meal_at_school'] || '--',
        Student_If_Yes_where_does_it_come_from: InfoseekMasterId['Student_If_Yes_where_does_it_come_from'] || '--',
        Student_If_Other: InfoseekMasterId['Student_If_Other'] ,
        Is_the_student_intolerant_to_any_food_group: InfoseekMasterId['Is_the_student_intolerant_to_any_food_group'] || '--',
        Food_Group_Other: InfoseekMasterId['Food_Group_Other'] ,
        On_an_average_isthe_student_freshandrelaxed_night_sleep: InfoseekMasterId['On_an_average_isthe_student_freshandrelaxed_night_sleep'] || '--',
        Does_the_student_have_any_sleep_related_issues: InfoseekMasterId['Does_the_student_have_any_sleep_related_issues'] || '--',
        Sleep_related_issues_Other: InfoseekMasterId['Sleep_related_issues_Other'] ,
        
        What_is_the_students_nap_cycle_during_day_Nap: InfoseekMasterId['What_is_the_students_nap_cycle_during_day_Nap'] || '--',
        What_is_the_students_nap_cycle_during_day_Nap_Duration: InfoseekMasterId['What_is_the_students_nap_cycle_during_day_Nap_Duration'] || '--',
        Social_personality_Other: InfoseekMasterId['Social_personality_Other'] ,
        Student_reaction_Other: InfoseekMasterId['Student_reaction_Other'] ,
        How_would_you_describe_student_rs_with_other_students_Other: InfoseekMasterId['How_would_you_describe_student_rs_with_other_students_Other'] ,
        How_would_you_describe_student_rs_with_other_students: InfoseekMasterId['How_would_you_describe_student_rs_with_other_students'] || '--',
        Does_the_student_have_any_significantly_close_friends: InfoseekMasterId['Does_the_student_have_any_significantly_close_friends'] || '--',
        student_close_friends_yes_how_many_boys: InfoseekMasterId['student_close_friends_yes_how_many_boys'] || '--',
        student_close_friends_yes_how_many_girls: InfoseekMasterId['student_close_friends_yes_how_many_girls'] || '--',
        Student_dissatisfied_Other: InfoseekMasterId['Student_dissatisfied_Other'] ,
        Any_students_family_Other: InfoseekMasterId['Any_students_family_Other'],
        Does_the_student_have_any_medicalissue_In_The_Past_Yes: InfoseekMasterId['Does_the_student_have_any_medicalissue_In_The_Past_Yes'] || '--',
        Does_the_student_have_any_medicalissue_In_The_Past: InfoseekMasterId['Does_the_student_have_any_medicalissue_In_The_Past'] || '--',
        Current_Medication: InfoseekMasterId['Current_Medication'] || '--',
        Past_Medication: InfoseekMasterId['Past_Medication'] || '--',
        Does_the_student_have_any_medicalissue_Currently: InfoseekMasterId['Does_the_student_have_any_medicalissue_Currently'] || '--',
        Any_Known_Allergies: InfoseekMasterId['Any_Known_Allergies'] || '--',
        Any_Known_Allergies_yes: InfoseekMasterId['Any_Known_Allergies_yes'] || '--',



        qrcode_image: InfoseekMasterId['qrcode_image'] || '--',
        upload_photo: InfoseekMasterId['upload_photo'],
        Blood_group: InfoseekMasterId['BloodGroup'] || '--',
        Emergency_MobileNumber_Registered_with_school: InfoseekMasterId['Emergency_MobileNumber_Registered_with_school'] || '--',
        Registered_EmailId: InfoseekMasterId['Registered_EmailId'] || '--',
        secondary_Registered_EmailId: InfoseekMasterId['Registered_EmailId'] || '--',
        Mothers_FullName: InfoseekMasterId['Mothers_FullName'] || '--',
        Mother_Registered_EmailId: InfoseekMasterId['Registered_EmailId'] || '--',
        Mother_Alternate_MobileNumber: InfoseekMasterId['Alternate_MobileNumber'] || '--',
        Mother_Alternate_EmailId: InfoseekMasterId['Registered_EmailId'] || '--',
        Family_Doctor_Name: InfoseekMasterId['Family_Doctor_Name'] || '--',
        Doctor_Contact_Number: InfoseekMasterId['Doctor_Contact_Number'] || '--',
        Doctor_Contact_EmailId: InfoseekMasterId['Registered_EmailId'] || '--',
        Doctor_Contact_Number_alternative: InfoseekMasterId['Doctor_Contact_Number'] || '--',
        Doctor_Contact_EmailId_alternative: InfoseekMasterId['Registered_EmailId'] || '--',
        scholl_name: InfoseekMasterId['class_name'] || '--',
        scholl_address: InfoseekMasterId['Area'] || '--',
        scholl_principal: InfoseekMasterId['pricipal'] || '--',
        Student_register_number: InfoseekMasterId['UIN'] || '--',
        Student_register_date: InfoseekMasterId['Student_DOB'] || '--',
        Student_section: InfoseekMasterId['section_name'] || '--',
        What_typeof_recreational_activity_doesthestudentenjoy: InfoseekMasterId['What_typeof_recreational_activity_doesthestudentenjoy'] || '--',
        Which_ofthe_following_activities_doesthestudentenjoy: InfoseekMasterId['Which_ofthe_following_activities_doesthestudentenjoy'] || '--',
        Usual_numberof_mealsday: InfoseekMasterId['Usual_numberof_mealsday'] || '--',
        Usual_numberof_snacksday: InfoseekMasterId['Usual_numberof_snacksday'] || '--',
        What_is_the_students_average_fluid_intake: InfoseekMasterId['What_is_the_students_average_fluid_intake'] || '--',
        Type_of_meal: InfoseekMasterId['Type_of_meal'] || '--',
        Does_the_student_have_any_sleep_related_issues_Yes: InfoseekMasterId['Does_the_student_have_any_sleep_related_issues_Yes'] || '--',
        What_is_the_students_average_length_of_sleep_per_night: InfoseekMasterId['What_is_the_students_average_length_of_sleep_per_night'] || '--',
        How_would_you_describe_the_social_personality_ofthe_student: InfoseekMasterId['How_would_you_describe_the_social_personality_ofthe_student'] || '--',
        Specify_if_the_student_has_any_irrational_fears_phobias: InfoseekMasterId['Specify_if_the_student_has_any_irrational_fears_phobias'] || '--',
        How_would_you_rate_the_students_self_image_self_worth: InfoseekMasterId['How_would_you_rate_the_students_self_image_self_worth'] || '--',
        How_would_you_rate_students_cooperation_in_housedhold_chores: InfoseekMasterId['How_would_you_rate_students_cooperation_in_housedhold_chores'] || '--',
        How_would_you_best_describe_the_student_reaction_to_change: InfoseekMasterId['How_would_you_best_describe_the_student_reaction_to_change'] || '--',
        The_student_goes_to_school: InfoseekMasterId['The_student_goes_to_school'] || '--',
        How_would_you_rate_student_overall_attendance_at_school: InfoseekMasterId['How_would_you_rate_student_overall_attendance_at_school'] || '--',
        What_is_student_general_opinion_of_school: InfoseekMasterId['What_is_student_general_opinion_of_school'] || '--',
        Do_you_have_concerns_following_respect_tothe_student: InfoseekMasterId['Do_you_have_concerns_following_respect_tothe_student'] || '--',
        Isthe_students_school_performance_affected_by_any_following: InfoseekMasterId['Isthe_students_school_performance_affected_by_any_following'] || '--',
        Isthe_student_significantly_dissatisfied_byany_following: InfoseekMasterId['Isthe_student_significantly_dissatisfied_byany_following'] || '--',
        How_would_you_rate_the_student_overall_physical_health: InfoseekMasterId['How_would_you_rate_the_student_overall_physical_health'] || '--',
        Any_Ongoing_Illnesscondition_membersofthe_students_family: InfoseekMasterId['Any_Ongoing_Illnesscondition_membersofthe_students_family'] || '--',
        Does_the_student_have_any_medicalissue_Currently_Yes: InfoseekMasterId['Does_the_student_have_any_medicalissue_Currently_Yes'] || '--',
      });
      console.log("Form Values After Patching:", this.stepForm.value);
    },
    (error: any) => {
      console.error('Error fetching InfoseekMasterId:', error);
    }
  );


  this.stepForm = this.fb.group({
    following_information_providedby:['',Validators.required],
    If_Other:['',Validators.required],
    Student_MiddleName1:['',Validators.required],
    Student_MiddleName2:['',Validators.required],
    Student_LastName:['',Validators.required],
    Student_FirstName: ['', Validators.required],
    Student_DOB: ['', Validators.required],
    Number_Of_Sisters: ['', Validators.required],
    Number_Of_Brothers: ['', Validators.required],
    Mothers_FirstName: ['', Validators.required],
    Mothers_MiddleName1: ['', Validators.required],
    Mothers_MiddleName2: ['', Validators.required],
    Mothers_LastName: ['', Validators.required],
    Mothers_Ethnicity: ['', Validators.required],
    Mothers_Ethnicity_If_Other: ['', Validators.required],
    Fathers_FirstName: ['', Validators.required],
    Fathers_MiddleName1: ['', Validators.required],
    Fathers_MiddleName2: ['', Validators.required],
    Fathers_LastName: ['', Validators.required],
    Fathers_Ethnicity: ['', Validators.required],
    Fathers_Ethnicity_If_Other: ['', Validators.required],
    Do_you_have_health_insurance: ['', Validators.required],
    Medical_Aid: ['', Validators.required],
    Policy_Card: ['', Validators.required],
    Expiry_Date: ['', Validators.required],
    Building_Name: ['', Validators.required],
    Apartment_Villa_No: ['', Validators.required],
    Street_No: ['', Validators.required],
    Area: ['', Validators.required],
    City: ['', Validators.required],
    Country: ['', Validators.required],
    State: ['', Validators.required],
    Zip_Code: ['', Validators.required],
    Emergency_MobileNumber_Registered_with_school: ['', Validators.required],
    Belongs_To: ['', Validators.required],
    Email_Registered_With_School: ['', Validators.required],
    Primary_Contact_Belongs_To: ['', Validators.required],
    Primary_Contact_Belongs_To_Other: ['', Validators.required],
    Primary_Contact: ['', Validators.required],
    Primary_Contact_Full_Name: ['', Validators.required],
    Secondary_Contact_Belongs_To: ['', Validators.required],
    Secondary_Contact_Belongs_To_Other: ['', Validators.required],
    Secondary_Contact_Full_Name: ['', Validators.required],
    Family_Doctor_Name: ['', Validators.required],
    Doctor_Contact_Number: ['', Validators.required],
    Activites_Other: ['', Validators.required],
    Do_you_have_pets_at_home: ['', Validators.required],
    Isthe_student_memberofasports_teamatschool_Yes: ['', Validators.required],
    Isthe_student_memberofasports_teamatschool: ['', Validators.required],
    Do_you_have_pets_at_home_Yes_Duration: ['', Validators.required],
    Do_you_have_pets_at_home_Yes_Animal: ['', Validators.required],
    Type_of_meal_Other: ['', Validators.required],
    Does_the_student_have_regular_meal_at_school: ['', Validators.required],
    Does_the_student_have_breakfast_regularly: ['', Validators.required],
    Student_If_Yes_where_does_it_come_from: ['', Validators.required],
    Student_If_Other: ['', Validators.required],
    Is_the_student_intolerant_to_any_food_group: ['', Validators.required],
    Food_Group_Other: ['', Validators.required],
    Does_the_student_have_any_sleep_related_issues: ['', Validators.required],
    Sleep_related_issues_Other: ['', Validators.required],
    What_is_the_students_nap_cycle_during_day_Nap_Duration: ['', Validators.required],
    Social_personality_Other: ['', Validators.required],
    Student_reaction_Other: ['', Validators.required],
    How_would_you_describe_student_rs_with_other_students: ['', Validators.required],
    How_would_you_describe_student_rs_with_other_students_Other: ['', Validators.required],
    Does_the_student_have_any_significantly_close_friends: ['', Validators.required],
    Student_dissatisfied_Other: ['', Validators.required],
    Any_students_family_Other: ['', Validators.required],
    Does_the_student_have_any_medicalissue_In_The_Past_Yes: ['', Validators.required],
    Does_the_student_have_any_medicalissue_In_The_Past: ['', Validators.required],
    Current_Medication: ['', Validators.required],
    Past_Medication: ['', Validators.required],
    Any_Known_Allergies: ['', Validators.required],
    Any_Known_Allergies_yes: ['', Validators.required],




    Rh_Factor: ['', Validators.required],
    class_name: ['', Validators.required],
    Registered_EmailId: ['', Validators.required],
    Secondary_Contact: ['', Validators.required],
    secondary_Registered_EmailId: ['', Validators.required],
    Mothers_FullName: ['', Validators.required],
    Alternate_MobileNumber: ['', Validators.required],
    Mother_Registered_EmailId: ['', Validators.required],
    Mother_Alternate_MobileNumber: ['', Validators.required],
    Mother_Alternate_EmailId: ['', Validators.required],
 
    Doctor_Contact_EmailId: ['', Validators.required],
    Doctor_Contact_Number_alternative: ['', Validators.required],
    Doctor_Contact_EmailId_alternative: ['', Validators.required],
    scholl_name: ['', Validators.required],
    scholl_address: ['', Validators.required],
    scholl_principal: ['', Validators.required],
    Student_register_number: ['', Validators.required],
    Student_register_date: ['', Validators.required],
    Student_section: ['', Validators.required],
    Blood_group: ['', Validators.required],
    Gender: ['', Validators.required],
    Age: ['', Validators.required],
    UIN: ['', Validators.required],
    What_typeof_recreational_activity_doesthestudentenjoy: ['', Validators.required],
    Which_ofthe_following_activities_doesthestudentenjoy:  ['', Validators.required],
    Usual_numberof_mealsday: ['', Validators.required],
    Usual_numberof_snacksday:  ['', Validators.required],
    What_is_the_students_average_fluid_intake:  ['', Validators.required],
    Type_of_meal:  ['', Validators.required],
    On_an_average_isthe_student_freshandrelaxed_night_sleep:  ['', Validators.required],
    Does_the_student_have_any_sleep_related_issues_Yes:  ['', Validators.required],
    What_is_the_students_average_length_of_sleep_per_night:  ['', Validators.required],
    What_is_the_students_nap_cycle_during_day_Nap:  ['', Validators.required],
    How_would_you_describe_the_social_personality_ofthe_student: ['', Validators.required],
    Specify_if_the_student_has_any_irrational_fears_phobias:  ['', Validators.required],
    How_would_you_rate_the_students_self_image_self_worth: ['', Validators.required],
    How_would_you_rate_students_cooperation_in_housedhold_chores: ['', Validators.required],
    How_would_you_best_describe_the_student_reaction_to_change:  ['', Validators.required],
    student_close_friends_yes_how_many_girls: ['', Validators.required],
    student_close_friends_yes_how_many_boys:  ['', Validators.required],
    The_student_goes_to_school:  ['', Validators.required],
    How_would_you_rate_student_overall_attendance_at_school:  ['', Validators.required],
    What_is_student_general_opinion_of_school:  ['', Validators.required],
    Do_you_have_concerns_following_respect_tothe_student: ['', Validators.required],
    Isthe_students_school_performance_affected_by_any_following: ['', Validators.required],
    Isthe_student_significantly_dissatisfied_byany_following: ['', Validators.required],
    How_would_you_rate_the_student_overall_physical_health:  ['', Validators.required],
    Any_Ongoing_Illnesscondition_membersofthe_students_family:  ['', Validators.required],
    Does_the_student_have_any_medicalissue_Currently_Yes:  ['', Validators.required],
    Does_the_student_have_any_medicalissue_Currently:  ['', Validators.required],

    StudentFirstName:['', Validators.required],
    qrcode_image:['', Validators.required],
    upload_photo:['', Validators.required],
    section_name:['', Validators.required],


  });
}
// getidcard(){
//     const verId = 188;
//     console.log(verId, 'prudhvi');
  
//     if (!verId) {
//       console.log('userId not found in local storage.');
//       return;
//     }
  
//     this.formservice.gethcpidcard(verId).subscribe(
//       (data: any) => {
//         if (data['Status'] === 200) {
//           const result = data.Result;
//           console.log(result, '123');
  
           
//           this.Upload_Your_Photo = result['Upload_Your_Photo'];
//           console.log('Profile Photo:', this.Upload_Your_Photo);
  
//           this.Hcp_qrcode = result['Hcp_qrcode'];
//           console.log('QR Code:', this.Hcp_qrcode);
  
//         console.log(data);
//       }},
//       (error) => {
//         console.error('Error:', error);
//         // Handle the error here (e.g., show an error message to the user)
//       }
//     );
//   }
  

infoseekidcard() {
  this.UIN = localStorage.getItem('UIN');
  console.log(this.UIN,"UIN")

  this.formservice.getidcard(this.UIN).subscribe(
    (data: any) => {
      const InfoseekMasterId = data.Result;

      this.StudentFirstName = InfoseekMasterId['Student_FirstName'];
      this.StudentDOB = InfoseekMasterId['Student_DOB'];
      this.classname = InfoseekMasterId['class_name'];
      this.uin = InfoseekMasterId['UIN'];
      this.Age = InfoseekMasterId['Age'];
      this.gender = InfoseekMasterId['Gender'];
      this.qrcode_image = InfoseekMasterId['qrcode_image'];
      this.upload_photo = InfoseekMasterId['upload_photo'];
      this.Registered_EmailId = InfoseekMasterId['Registered_EmailId'];
      this.Primary_Contact = InfoseekMasterId['Primary_Contact'];

      console.log("ID Card Values:", InfoseekMasterId);
    },
    (error: any) => {
      console.error('Error fetching ID Card:', error);
    }
  );
}
}
