import { Component, OnInit,Input } from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { FormService } from '../../form.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-step-ten-general-hisotry',
  templateUrl: './step-ten-general-hisotry.component.html',
  styleUrls: ['./step-ten-general-hisotry.component.scss'],
  providers: [FormGroupDirective]  // Add this line

})
export class StepTenGeneralHisotryComponent {
  disabled = false;
  max = 100;
  min = 0;
  showTicks = false;
  step = 1;
  thumbLabel = false;
  value = 0;

  @Input() stepForm!: FormGroup;
  formService: any;
  userId: string;
  selectedNumbers: any[] = [];
  selectedNumber: any[] = [];
  selectedNumbe: any[] = [];
  studentPhotoImageUrl: any;
  studentPhotofilename: string;
  sliderScaleValues: number[] = [0,1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  selectedSliderValue: any;
  showOtherInput: boolean=false;
  sliderLabel: string;
  disableAllExceptNoConcerns: boolean = false;



  constructor(
    private rootFormGroup: FormGroupDirective,
    private formservice: FormService ,
    private fb: FormBuilder,
    private routes:Router
  ){}

  ngOnInit(): void {
    this.userId = localStorage.getItem('userId');
    console.log(this.userId, "fghjk");

    // Fetch additional information based on userId
    this.formservice.InfoseekMasterId(this.userId).subscribe(
      (data: any) => {
        const infoSeekData = data.Result[0];
        this.studentPhotoImageUrl = infoSeekData['upload_photo'];

        if (this.studentPhotoImageUrl) {
          const filename = new URL(this.studentPhotoImageUrl).pathname.split('/').pop();
          this.studentPhotofilename = filename; // Store filename for display
          console.log( this.studentPhotofilename,"image name");
        } else {
          console.warn('studentPhotoImageUrl is null or undefined.');
          this.studentPhotofilename = ''; // Clear placeholder if URL is unavailable
        }
        this.stepForm.patchValue({
          Do_you_have_concerns_following_respect_tothe_student: infoSeekData['Do_you_have_concerns_following_respect_tothe_student'],
          How_would_you_rate_the_student_overall_physical_health: infoSeekData['How_would_you_rate_the_student_overall_physical_health'],
          Isthe_students_school_performance_affected_by_any_following: infoSeekData['Isthe_students_school_performance_affected_by_any_following'],
          Isthe_student_significantly_dissatisfied_byany_following: infoSeekData['Isthe_student_significantly_dissatisfied_byany_following'],
          Student_dissatisfied_Other: infoSeekData['Student_dissatisfied_Other'],
        });
        console.log("Form Values After Patching:", this.stepForm.value);

        // Additionally, update the arrays tracking selected checkboxes
        this.selectedNumbers = infoSeekData['Do_you_have_concerns_following_respect_tothe_student']
          ? infoSeekData['Do_you_have_concerns_following_respect_tothe_student'].split(',')
          : [];
        this.selectedNumber = infoSeekData['Isthe_students_school_performance_affected_by_any_following']
          ? infoSeekData['Isthe_students_school_performance_affected_by_any_following'].split(',')
          : [];
        this.selectedNumbe = infoSeekData['Isthe_student_significantly_dissatisfied_byany_following']
          ? infoSeekData['Isthe_student_significantly_dissatisfied_byany_following'].split(',')
          : [];
          this.toggleOtherInput();

      },
      (error: any) => {
        console.error('Error fetching InfoseekMasterId:', error);
      }
    );
    this.stepForm = this.fb.group({
      Do_you_have_concerns_following_respect_tothe_student: [''],
      How_would_you_rate_the_student_overall_physical_health: [''],
      Isthe_students_school_performance_affected_by_any_following: [''],
      Isthe_student_significantly_dissatisfied_byany_following: [''],
      Student_dissatisfied_Other: [''],
    });
  }
 
  updateSliderValue(event: any): void {
    const formControl = this.stepForm.get('How_would_you_rate_the_student_overall_physical_health');
  
    if (formControl) {
      this.selectedSliderValue = formControl.value;
      this.sliderLabel = this.selectedSliderValue < 5 ? 'Very Poor' : 'Excellent';
      console.log(this.selectedSliderValue, "number");
    } else {
      console.error('Form control not found.');
    }
  }
  isCheckboxChecked(value: string): boolean {
    return this.selectedNumbers.includes(value);
  }
isCheckbox(value: string): boolean {
  const formValue = this.stepForm.get('Isthe_students_school_performance_affected_by_any_following')?.value;
  return formValue ? formValue.includes(value) : false;
}
isCheckboxCheck(value: string): boolean {
  const formValue = this.stepForm.get('Isthe_student_significantly_dissatisfied_byany_following')?.value;
  return formValue ? formValue.includes(value) : false;
}
numberssaved(event: any, value: string) {
  console.log('Checkbox changed:', event, value,this.selectedNumbe);

  if (event.checked) {
    // Add the value to the array if the checkbox is checked
    this.selectedNumbe.push(value);
    
  } else {
    // Remove the value from the array if the checkbox is unchecked
    this.selectedNumbe = this.selectedNumbe.filter((item: string) => item !== value);
  }
  this.toggleOtherInput();
  console.log('Selected Numbers:', this.selectedNumbe);
}

toggleOtherInput(): void {
  console.log("select");
  const activitiesOtherChecked = this.selectedNumbe.includes('Other');

  if (activitiesOtherChecked) {
    this.showOtherInput = true;
  } else {
    this.showOtherInput = false;
    this.stepForm.get('Student_dissatisfied_Other')?.setValue(''); // Clear the input field
  }

  if (this.showOtherInput) {
    this.stepForm.get('Student_dissatisfied_Other')?.setValidators(Validators.required);
  } else {
    this.stepForm.get('Student_dissatisfied_Other')?.clearValidators();
  }
  this.stepForm.get('Student_dissatisfied_Other')?.updateValueAndValidity();
}

performance(event: any, value: string) {
  if (event.checked) {
    // Add the value to the array if the checkbox is checked
    this.selectedNumber = [...this.selectedNumber, value];
  } else {
    // Remove the value from the array if the checkbox is unchecked
    this.selectedNumber = this.selectedNumber.filter((item: string) => item !== value);
  }

  console.log('Selected Numbers:', this.selectedNumber);
}
dissatisfied(event: any, value: string) {
  if (value === 'No significant concerns') {
    if (event.checked) {
      // Clear all other selections and keep only "No significant concerns"
      this.selectedNumbers = ['No significant concerns'];
      this.disableAllExceptNoConcerns = true;
    } else {
      // If "No significant concerns" is unchecked, re-enable other checkboxes
      this.disableAllExceptNoConcerns = false;
      this.selectedNumbers = [];
    }
  } else {
    if (event.checked) {
      // Add the value to the array if the checkbox is checked
      this.selectedNumbers.push(value);
    } else {
      // Remove the value from the array if the checkbox is unchecked
      this.selectedNumbers = this.selectedNumbers.filter((item: string) => item !== value);
    }
  }

  console.log('selectedNumbers:', this.selectedNumbers);
}


goToPrevious(){
  this.routes.navigate(['/stepeight'])

}

nextButtonClick(): void {
  this.formservice.incrementActiveStep();
}

onSubmit() {

  const sectionD = {
    Do_you_have_concerns_following_respect_tothe_student: this.selectedNumbers.join(',') || null,
    How_would_you_rate_the_student_overall_physical_health: this.stepForm.get('How_would_you_rate_the_student_overall_physical_health')?.value || null,
    Isthe_students_school_performance_affected_by_any_following: this.selectedNumber.join(',')|| null,
    Isthe_student_significantly_dissatisfied_byany_following: this.selectedNumbe.join(',')|| null,
    Student_dissatisfied_Other: this.stepForm.get('Student_dissatisfied_Other')?.value|| null,

  };




console.log(sectionD,"sectionA")

const userId = localStorage.getItem('userId');
const formData = new FormData();

// Append form values to formData

this.formservice.updateUserRegJ(userId, sectionD).subscribe(
  (data: any) => {
    if (data['Status'] === 200) {
      console.log('User data updated successfully');
      //this.routes.navigate(['/stepeleven'])
      this.nextButtonClick();
    }
  },
  (error) => {
    console.error('Error updating user data:', error);
  }
);
}
}

