import { Component, OnInit,Input } from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { FormService } from '../../form.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-step-eight-personality-social-interaction',
  templateUrl: './step-eight-personality-social-interaction.component.html',
  styleUrls: ['./step-eight-personality-social-interaction.component.scss'],
  providers: [FormGroupDirective]  // Add this line

})
export class StepEightPersonalitySocialInteractionComponent {
  disabled = false;
  max = 100;
  min = 0;
  showTicks = false;
  step = 1;
  thumbLabel = false;
  value = 0;
  nextButtonClicked=false
  @Input() stepForm!: FormGroup;
  formService: any;
  userId: string;
  studentPhotoImageUrl: any;
  studentPhotofilename: string;
  showTextBox: boolean = false;
  reaction: boolean = false;
  relationship: boolean = false;
  maxChars = 100;
  sliderScaleValues: number[] = [0,1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  selectedSliderValue: any;
  sliderLabel: string;



  constructor(
    private rootFormGroup: FormGroupDirective,
    private formservice: FormService ,
    private fb: FormBuilder,
    private routes:Router
  ){}
  goToPrevious(){
    this.routes.navigate(['/stepseven'])
  }

  updateSliderValue(event: any): void {
    const formControl = this.stepForm.get('How_would_you_rate_students_cooperation_in_housedhold_chores');
  
    if (formControl) {
      this.selectedSliderValue = formControl.value;
      this.sliderLabel = this.selectedSliderValue < 5 ? 'Very Poor' : 'Excellent';
      console.log(this.selectedSliderValue, "number");
    } else {
      console.error('Form control not found.');
    }
  }
  nextButtonClick(): void {
    this.formservice.incrementActiveStep();
  }
  onRadioChange(event: any) {
    this.showTextBox = event.value === 'Other';
  
    // If the input field is not shown, clear the value
    if (!this.showTextBox) {
      this.stepForm.get('Social_personality_Other').setValue('');
    }
  }
  reactionChange(event: any) {
    this.reaction = event.value === 'Other';
  
    // If the input field is not shown, clear the value
    if (!this.reaction) {
      this.stepForm.get('Student_reaction_Other').setValue('');
    }
  }
  onNextButtonClick() {
    this.nextButtonClicked = true;
    
  }
  relationshipChange(event: any) {
    this.relationship = event.value === 'Other';
  
    // If the input field is not shown, clear the value
    if (!this.relationship) {
      this.stepForm.get('How_would_you_describe_student_rs_with_other_students_Other').setValue('');
    }
  }
  
 ngOnInit(): void {

  this.userId = localStorage.getItem('userId');
  console.log(this.userId, "fghjk");

  // Fetch additional information based on userId
  this.formservice.InfoseekMasterId(this.userId).subscribe(
    (data: any) => {
      const InfoseekMasterId = data.Result[0];

      this.studentPhotoImageUrl = InfoseekMasterId['upload_photo'];

      if (this.studentPhotoImageUrl) {
        const filename = new URL(this.studentPhotoImageUrl).pathname.split('/').pop();
        this.studentPhotofilename = filename; // Store filename for display
        console.log( this.studentPhotofilename,"image name");
      } else {
        console.warn('studentPhotoImageUrl is null or undefined.');
        this.studentPhotofilename = ''; // Clear placeholder if URL is unavailable
      }
      this.stepForm.patchValue({
        How_would_you_describe_the_social_personality_ofthe_student: InfoseekMasterId['How_would_you_describe_the_social_personality_ofthe_student'],
        Social_personality_Other: InfoseekMasterId['Social_personality_Other'],
        Specify_if_the_student_has_any_irrational_fears_phobias: InfoseekMasterId['Specify_if_the_student_has_any_irrational_fears_phobias'],
        How_would_you_rate_the_students_self_image_self_worth: InfoseekMasterId['How_would_you_rate_the_students_self_image_self_worth'],
        How_would_you_rate_students_cooperation_in_housedhold_chores: InfoseekMasterId['How_would_you_rate_students_cooperation_in_housedhold_chores'],
        How_would_you_best_describe_the_student_reaction_to_change: InfoseekMasterId['How_would_you_best_describe_the_student_reaction_to_change'],
        Student_reaction_Other: InfoseekMasterId['Student_reaction_Other'],
        How_would_you_describe_student_rs_with_other_students: InfoseekMasterId['How_would_you_describe_student_rs_with_other_students'],
        How_would_you_describe_student_rs_with_other_students_Other: InfoseekMasterId['How_would_you_describe_student_rs_with_other_students_Other'],
        Does_the_student_have_any_significantly_close_friends: InfoseekMasterId['Does_the_student_have_any_significantly_close_friends'] || 'No',
        student_close_friends_yes_how_many_girls: InfoseekMasterId['student_close_friends_yes_how_many_girls'],
        student_close_friends_yes_how_many_boys: InfoseekMasterId['student_close_friends_yes_how_many_boys'],

      });
      console.log("Form Values After Patching:", this.stepForm.value);
    },
    (error: any) => {
      console.error('Error fetching InfoseekMasterId:', error);
    }
  );

  this.stepForm = this.fb.group({
    How_would_you_describe_the_social_personality_ofthe_student: ['', Validators.required],
    Social_personality_Other: ['', Validators.required],
    Specify_if_the_student_has_any_irrational_fears_phobias: ['', Validators.required],
    How_would_you_rate_the_students_self_image_self_worth: ['', Validators.required],
    How_would_you_rate_students_cooperation_in_housedhold_chores: ['', Validators.required],
    How_would_you_best_describe_the_student_reaction_to_change: ['', Validators.required],
    Student_reaction_Other: ['', Validators.required],
    How_would_you_describe_student_rs_with_other_students: ['', Validators.required],
    How_would_you_describe_student_rs_with_other_students_Other: ['', Validators.required],
    Does_the_student_have_any_significantly_close_friends: ['', Validators.required],
    student_close_friends_yes_how_many_girls: ['', Validators.required],
    student_close_friends_yes_how_many_boys: ['', Validators.required],



  });
}
onSubmit() {

  const sectionD={
    How_would_you_describe_the_social_personality_ofthe_student:this.stepForm.get('How_would_you_describe_the_social_personality_ofthe_student')?.value|| null,
    Social_personality_Other:this.stepForm.get('Social_personality_Other')?.value|| null,
    Specify_if_the_student_has_any_irrational_fears_phobias:this.stepForm.get('Specify_if_the_student_has_any_irrational_fears_phobias')?.value|| null,
    How_would_you_rate_the_students_self_image_self_worth:this.stepForm.get('How_would_you_rate_the_students_self_image_self_worth')?.value|| null,
    How_would_you_rate_students_cooperation_in_housedhold_chores:this.stepForm.get('How_would_you_rate_students_cooperation_in_housedhold_chores')?.value|| null,
    How_would_you_best_describe_the_student_reaction_to_change:this.stepForm.get('How_would_you_best_describe_the_student_reaction_to_change')?.value|| null,
    Student_reaction_Other:this.stepForm.get('Student_reaction_Other')?.value|| null,
    How_would_you_describe_student_rs_with_other_students:this.stepForm.get('How_would_you_describe_student_rs_with_other_students')?.value|| null,
    How_would_you_describe_student_rs_with_other_students_Other:this.stepForm.get('How_would_you_describe_student_rs_with_other_students_Other')?.value|| null,
    Does_the_student_have_any_significantly_close_friends:this.stepForm.get('Does_the_student_have_any_significantly_close_friends')?.value|| null,
    student_close_friends_yes_how_many_girls:this.stepForm.get('student_close_friends_yes_how_many_girls')?.value|| null,
    student_close_friends_yes_how_many_boys:this.stepForm.get('student_close_friends_yes_how_many_boys')?.value|| null,



}
console.log(sectionD,"sectionA")

const userId = localStorage.getItem('userId');
const formData = new FormData();

// Append form values to formData

this.formservice.updateUserRegH(userId, sectionD).subscribe(
  (data: any) => {
    if (data['Status'] === 200) {
      console.log('User data updated successfully');
      //this.routes.navigate(['/stepnine'])
      this.nextButtonClick();
    }
  },
  (error) => {
    console.error('Error updating user data:', error);
  }
);
}
}
