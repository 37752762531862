import { Component, OnInit,Input } from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { FormService } from '../../form.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSliderChange } from '@angular/material/slider';
@Component({
  selector: 'app-step-nine-life-at-school',
  templateUrl: './step-nine-life-at-school.component.html',
  styleUrls: ['./step-nine-life-at-school.component.scss'],
  providers: [FormGroupDirective]  // Add this line

})
export class StepNineLifeAtSchoolComponent {
  disabled = false;
  max = 100;
  min = 0;
  showTicks = false;
  step = 1;
  thumbLabel = false;
  value = 0;
  startvalue: number = 0;
  endvalue: number = 100;
  gridsize: number = 30;
  sliderControlName = 'sliderValue';







  @Input() stepForm!: FormGroup;
  formService: any;
  userId: string;
  studentPhotoImageUrl: any;
  studentPhotofilename: string;
  cdr: any;
  someValue = 0;
  startValueControl: any;
  endValueControl: any;
  sliderScaleValues: number[] = [0,1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  selectedSliderValue: any;
  sliderLabel: string;

  constructor(
    private rootFormGroup: FormGroupDirective,
    private formservice: FormService ,
    private fb: FormBuilder,
    private routes:Router
  ){ this.stepForm = this.fb.group({
    startValue: this.startValueControl,
    endValue: this.endValueControl,
  });}

  How_would_you_rate_student_overall_attendance_at_school: FormControl = new FormControl(0);

  goToPrevious(){
    this.routes.navigate(['/stepseven'])

  }
  
  updateSomeValue(event: MatSliderChange): void {
    this.someValue = event.value;
  }
  nextButtonClick(): void {
    this.formservice.incrementActiveStep();
  }
  updateSliderValue(event: any): void {
    const formControl = this.stepForm.get('How_would_you_rate_student_overall_attendance_at_school');
  
    if (formControl) {
      this.selectedSliderValue = formControl.value;
      this.sliderLabel = this.selectedSliderValue < 5 ? 'Very Poor' : 'Excellent';
      console.log(this.selectedSliderValue, "number");
    } else {
      console.error('Form control not found.');
    }
  }
 ngOnInit(): void {

  this.userId = localStorage.getItem('userId');
  console.log(this.userId, "fghjk");

  // Fetch additional information based on userId
  this.formservice.InfoseekMasterId(this.userId).subscribe(
    (data: any) => {
      const InfoseekMasterId = data.Result[0];
      this.studentPhotoImageUrl = InfoseekMasterId['upload_photo'];

      if (this.studentPhotoImageUrl) {
        const filename = new URL(this.studentPhotoImageUrl).pathname.split('/').pop();
        this.studentPhotofilename = filename; // Store filename for display
        console.log( this.studentPhotofilename,"image name");
      } else {
        console.warn('studentPhotoImageUrl is null or undefined.');
        this.studentPhotofilename = ''; // Clear placeholder if URL is unavailable
      }
      
      this.stepForm.patchValue({
        What_is_student_general_opinion_of_school: InfoseekMasterId['What_is_student_general_opinion_of_school'],
        The_student_goes_to_school: InfoseekMasterId['The_student_goes_to_school'],
        How_would_you_rate_student_overall_attendance_at_school: InfoseekMasterId['How_would_you_rate_student_overall_attendance_at_school'],


      });
      const attendanceValue = InfoseekMasterId['How_would_you_rate_student_overall_attendance_at_school'];
      this.stepForm.get('How_would_you_rate_student_overall_attendance_at_school').setValue(attendanceValue);

      console.log("Form Values After Patching:", this.stepForm.value);
    },
    (error: any) => {
      console.error('Error fetching InfoseekMasterId:', error);
    }
  );
  

  this.stepForm = this.fb.group({
    What_is_student_general_opinion_of_school: ['',Validators.required],
    The_student_goes_to_school: ['',Validators.required],
    How_would_you_rate_student_overall_attendance_at_school: [0, Validators.required],



  });
}

onSubmit() {

  const sectionD={
    What_is_student_general_opinion_of_school:this.stepForm.get('What_is_student_general_opinion_of_school')?.value|| null,
    The_student_goes_to_school:this.stepForm.get('The_student_goes_to_school')?.value|| null,
    How_would_you_rate_student_overall_attendance_at_school:this.stepForm.get('How_would_you_rate_student_overall_attendance_at_school')?.value|| null,



}
console.log(sectionD,"sectionA")

const userId = localStorage.getItem('userId');
const formData = new FormData();

// Append form values to formData

this.formservice.updateUserRegI(userId, sectionD).subscribe(
  (data: any) => {
    if (data['Status'] === 200) {
      console.log('User data updated successfully');
      //this.routes.navigate(['/stepten'])
      this.nextButtonClick();
    }
  },
  (error) => {
    console.error('Error updating user data:', error);
  }
);
}

}

