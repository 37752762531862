<div class="row overflow-hidden">
  <div class="col-md-12 p-r-32 p-l-32">
    <ng-container>
      <div class="boxshadow-form">
        <!--Header Block starts here Designed by Krishna Kanth on 18th Jan 2024-->
        <div class="title text-center p-t-20 d-flex justify-content-center">
          <p class="marroncolor f-w-500 m-t-24 f-s-24 m-t-24 d-flex align-items-center">B. Health Insurance Information</p>
          <label for="digitalsigninput" class="uploadedimages">
            <img class="uploadedimage" style="width: 100px;" *ngIf="studentPhotoImageUrl" [src]="studentPhotoImageUrl">
            <div *ngIf="!studentPhotoImageUrl">
            <img mat-card-image src="/assets/images/svgs/upload-image.svg" alt="Upload Icon" />
            </div>
          </label>
          
        </div>
        <!--Header Block starts here Designed by Krishna Kanth on 18th Jan 2024-->

        <mat-card-content>
          <form [formGroup]="stepForm" (ngSubmit)="onSubmit()">
            <div id="scrollbar" class="internalform">
              <div class="m-t-32 m-b-32">
                <mat-label class="mat-subtitle-2 d-block formlabel">
                  Does the Student have health Insurance?<sup class="required-field"></sup>
                </mat-label>
                <mat-radio-group id="nradiobutton" aria-label="Select an option" formControlName="Do_you_have_health_insurance" class="m-b-32">
                  <mat-radio-button value="Yes">Yes</mat-radio-button>
                  <mat-radio-button value="No">No</mat-radio-button>

                </mat-radio-group>
                <div *ngIf="nextButtonClicked && stepForm.get('Do_you_have_health_insurance').hasError('required')" class="error-message">
                  Please select Does the Student have health Insurance?
                </div>
              </div>

              <!-- Show/hide fields based on the selected option -->
              <div *ngIf="stepForm.get('Do_you_have_health_insurance').value === 'Yes'">
                <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16 formlabel">Health Insurance<sup class="required-field"></sup></mat-label>
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput  placeholder="Health Insurance" formControlName="Medical_Aid">
                </mat-form-field>
                <mat-error *ngIf="nextButtonClicked && stepForm.get('Medical_Aid').hasError('required')"  class="error-message">
                  Health Insurance required
                </mat-error>
              
                <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16 formlabel">Health Insurance Plan Name<sup class="required-field"></sup></mat-label>
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput  placeholder="Health Insurance Plan Name" formControlName="Policy_Name">
                </mat-form-field>
                <mat-error *ngIf="nextButtonClicked && stepForm.get('Policy_Name').hasError('required')"  class="error-message">
                  Health Insurance Plan Name required
                </mat-error>
                <div class="row">
                  <div class="col-md-12">
                <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16 formlabel">Policy #<sup class="required-field"></sup></mat-label>
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput placeholder="Policy Number" formControlName="Policy_Card" maxlength="20" pattern="[0-9]*">
                </mat-form-field>
                
                <mat-error *ngIf="nextButtonClicked && stepForm.get('Policy_Card').hasError('required')" class="error-message">
                  Policy Number is required.                </mat-error>
                <mat-error *ngIf="stepForm.get('Policy_Card')?.hasError('maxlength')"  class="error-message">
                  Policy Number cannot exceed 20 digits.
                </mat-error>
                <mat-error *ngIf="stepForm.get('Policy_Card')?.hasError('pattern')"  class="error-message">
                  Policy Number must be numeric.
                </mat-error>
              </div>
                </div>
                <div class="row">
              <div class="col-md-12">
                <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16 formlabel">Policy Expiry Date<sup class="required-field"></sup></mat-label>
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput [matDatepicker]="startPicker" placeholder="DD/MMM/YYYY" formControlName="startdate">
                  <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                  <mat-datepicker #startPicker></mat-datepicker>
              </mat-form-field>
                <mat-error *ngIf="nextButtonClicked &&stepForm.get('startdate').hasError('required')"  class="error-message">
                  Policy Expiry Date required
                </mat-error>
              </div>
            </div>
              </div>
            </div>

            <div class="row justify-content-end m-t-32 float-right">
              <div class="col-lg-12 p-r-64">
                <app-previousbutton></app-previousbutton>
                <app-nextbutton (click)="onNextButtonClick()" ></app-nextbutton>
              </div>
            </div>
          </form>
        </mat-card-content>
      </div>
    </ng-container>
  </div>
</div>
