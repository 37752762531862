<div class="row">
  <div class="col-md-12 p-r-32 p-l-32">
    <ng-container [formGroup]="stepForm">
      <div class="step-one boxshadow-form">
        <!--Header Block starts here Designed by Krishna Kanth on 18th Jan 2024-->
        <div class="title text-center p-t-20 d-flex justify-content-center">
          <p class="marroncolor f-w-500 m-t-24 f-s-24 m-t-24 d-flex align-items-center">G. Sleep Pattern & Quality</p>
          <label for="digitalsigninput" class="uploadedimages">
            <img class="uploadedimage" style="width: 100px;" *ngIf="studentPhotoImageUrl" [src]="studentPhotoImageUrl">
            <div *ngIf="!studentPhotoImageUrl">
            <img mat-card-image src="/assets/images/svgs/upload-image.svg" alt="Upload Icon" />
            </div>
            </label>
        </div>
        <!--Header Block starts here Designed by Krishna Kanth on 18th Jan 2024-->

        <mat-card-content>
          <form [formGroup]="stepForm" (ngSubmit)="onSubmit()">
            <div id="scrollbar" class="internalform">
              <div class="row m-t-40">
                <div class="col-md-12 m-t-40">
                  <mat-label class="formlabel">on an average is the student fresh and relaxed after Night's Sleep?</mat-label>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Type Here" formControlName="On_an_average_isthe_student_freshandrelaxed_night_sleep">
                  </mat-form-field>
                </div>
                <div class="col-md-12">
                  <mat-label class="formlabel">Does the Student have any Sleep related issues (Tick all that are applicable)</mat-label>
                  <mat-radio-group id="nradiobutton" formControlName="Does_the_student_have_any_sleep_related_issues">
                    <mat-radio-button value="Yes">Yes</mat-radio-button>
                    <mat-radio-button value="No">No</mat-radio-button>
                  </mat-radio-group >
                  <div *ngIf="stepForm.get('Does_the_student_have_any_sleep_related_issues').value === 'Yes'">
                    <mat-error *ngIf="nextButtonClicked && stepForm.get('Does_the_student_have_any_sleep_related_issues')" class="error-message">
                      Please provide details about Student have any sleep related issues
                    </mat-error>
                  <section>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Awakens due to nightmares')" (change)="numberssaved($event, 'Awakens due to nightmares')">Awakens due to nightmares</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Bed wetting')" (change)="numberssaved($event, 'Bed wetting')">Bed wetting</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Coughs during sleep')" (change)="numberssaved($event, 'Coughs during sleep')">Coughs during sleep</mat-checkbox></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Frequent trips to toilet')" (change)="numberssaved($event, 'Frequent trips to toilet')">Frequent trips to toilet</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Sleep breathlessness')" (change)="numberssaved($event, 'Sleep breathlessness')">Sleep breathlessness</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Restless sleep')" (change)="numberssaved($event, 'Restless sleep')">Restless sleep</mat-checkbox></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Snoring')" (change)="numberssaved($event, 'Snoring')">Snoring</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Talks in sleep')" (change)="numberssaved($event, 'Talks in sleep')">Talks in sleep</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Takes long to fall asleep')" (change)="numberssaved($event, 'Takes long to fall asleep')">Takes long to fall asleep</mat-checkbox></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Unable to fall asleep')" (change)="numberssaved($event, 'Unable to fall asleep')">Unable to fall asleep</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Walks in sleep')" (change)="numberssaved($event, 'Walks in sleep')">Walks in sleep</mat-checkbox></div>
                      <mat-checkbox [checked]="isCheckboxChecked('Other')"  class="col-md-4" (change)="numberssaved($event, 'Other')">Other</mat-checkbox>
                    </div>
                   
                  <div class="row" *ngIf="showOtherInput">
                    <div class="col-md-12">
                      <mat-form-field appearance="outline" class="w-100">
                        <input matInput placeholder="If others, type here"  formControlName="Sleep_related_issues_Other">
                        <mat-error class="error-message" *ngIf="showOtherInput && stepForm.get('Sleep_related_issues_Other').invalid && stepForm.get('Sleep_related_issues_Other')">Other activity is required.</mat-error>

                      </mat-form-field>
                    </div>
                  </div>
                  </section>
                  </div>
                </div>
                <div class="col-md-12  m-t-20">
                  <mat-label class="formlabel">What is the Student's average length of Sleep per Night?</mat-label>
                  <mat-radio-group id="nradiobutton" formControlName= "What_is_the_students_average_length_of_sleep_per_night">
                    <mat-radio-button value="Less than 8 hours">Less than 8 hours</mat-radio-button>
                    <mat-radio-button value="More than 8 hours">More than 8 hours</mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="col-md-12 m-t-20">
                  <mat-label class="formlabel">What is the Student's Nap Cycle during the Day?</mat-label>
                  
                  <div class="row" *ngFor="let input of inputs; let i = index">
                     
                      <div class="col-md-4">

                      <mat-form-field appearance="outline" class="w-100">
                        <input matInput placeholder="nap" [formControl]="input.sleep">
                        <mat-error *ngIf="input.sleep.invalid && input.sleep.touched">nap is required.</mat-error>
                      </mat-form-field>
                      
                      </div>
                    <div class="col-md-4">
                     
                      <mat-form-field appearance="outline" class="w-100">
                        <input matInput placeholder="(Duration in nap)" [formControl]="input.input">
                        <mat-error *ngIf="input.input.invalid && input.input.touched">Duration is required.</mat-error>
                      </mat-form-field>
                    </div>
                   
                    <div class="col-md-4 m-t-9">
                      <button type="button" class="nextbutton" mat-raised-button (click)="addInput()">+</button>
                      <button mat-raised-button  class="nextbutton m-l-20" (click)="removeInput(i)" *ngIf="inputs.length > 1">-</button>
                    </div>
                  </div>

                </div>

              </div>
            </div>

            <div class="row justify-content-end m-t-32 float-right">
              <div class="col-lg-12 p-r-64">
                <app-previousbutton></app-previousbutton>
                <app-nextbutton (click)="onNextButtonClick"></app-nextbutton>
              </div>
            </div>
          </form>
        </mat-card-content>
      </div>
    </ng-container>
  </div>
</div>
