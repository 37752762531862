import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormComponent } from './components/form/form.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';

import { MatIconModule } from '@angular/material/icon';

import { StepOneStudentPersonalInfoComponent } from './components/form/form-steps/step-one-student-personal-info/step-one-personal-details.component';
import { StepTwoHealthInsuranceInformationComponent } from './components/form/form-steps/step-two-health-insurance-information/step-two-health-insurance-information';
import { StepThreeAddOnsComponent } from './components/form/form-steps/step-three-add-ons/step-three-add-ons.component';
import { StepFiveConfimComponent } from './components/form/form-steps/step-five-confim/step-five-confim.component';
import { StepFourComponent } from './components/form/form-steps/step-four/step-four.component';
import { StepSixComponent } from './components/form/form-steps/step-six/step-six.component';
import { StepSevenSleepPatternQualityComponent } from './components/form/form-steps/step-seven-sleep-pattern-quality/step-seven-sleep-pattern-quality.component';
import { StepEightPersonalitySocialInteractionComponent } from './components/form/form-steps/step-eight-personality-social-interaction/step-eight-personality-social-interaction.component';
import { StepNineLifeAtSchoolComponent } from './components/form/form-steps/step-nine-life-at-school/step-nine-life-at-school.component';
import { StepTenGeneralHisotryComponent } from './components/form/form-steps/step-ten-general-hisotry/step-ten-general-hisotry.component';
import { StepElevenFamilyHistoryComponent } from './components/form/form-steps/step-eleven-family-history/step-eleven-family-history.component';
import { StepTwelveMedicalHistoryComponent } from './components/form/form-steps/step-twelve-medical-history/step-twelve-medical-history.component';
import { FinalPreviewComponent } from './components/form/form-steps/final-preview/final-preview.component';

import { DateAdapter, ErrorStateMatcher, MAT_DATE_FORMATS, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { ProgressionButtonsComponent } from './components/progression-buttons/progression-buttons.component';
import { StepTrackerIconsComponent } from './components/step-nav/step-tracker-icons.component';
import { MaterialModule } from './material.module';
import { OtpComponent } from './components/otp/otp.component';
import { TermsComponent } from './components/terms/terms.component';
import { LoginComponent } from './components/login/login.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { StepInfoseekSummaryComponent } from './components/form/form-steps/step-infoseek-summary/step-infoseek-summary.component';

import { CommonModule } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';

import { UiComponentsComponent } from './components/ui-components/ui-components.component';
import { ButtonComponent } from './components/ui-components/button/button.component';
import { TextboxComponent } from './components/ui-components/textbox/textbox.component';
import { HrComponent } from './components/ui-components/hr/hr.component';
import { IdCardComponent } from './components/id-card/id-card.component';
import { PagenotfoundComponent } from './components/pagenotfound/pagenotfound.component';
import { NextbuttonComponent } from './components/ui-components/nextbutton/nextbutton.component';
import { PreviousbuttonComponent } from './components/ui-components/previousbutton/previousbutton.component';
import { MatSliderModule } from '@angular/material/slider';
import { CUSTOM_DATE_FORMATS } from './components/custum-date-formater';
import { CustomDateAdapter } from './components/custum-date-adapter';

@NgModule({
  declarations: [
    AppComponent,
    StepTrackerIconsComponent,
    ProgressionButtonsComponent,
    FormComponent,
    StepOneStudentPersonalInfoComponent,
    StepTwoHealthInsuranceInformationComponent,
    StepThreeAddOnsComponent,
    StepFiveConfimComponent,
    StepFourComponent,
    StepSixComponent,
    StepSevenSleepPatternQualityComponent,
    StepEightPersonalitySocialInteractionComponent,
    StepNineLifeAtSchoolComponent,
    StepTenGeneralHisotryComponent,
    StepElevenFamilyHistoryComponent,
    StepTwelveMedicalHistoryComponent,
    StepInfoseekSummaryComponent,
    OtpComponent,
    TermsComponent,
    LoginComponent,
    UiComponentsComponent,
    ButtonComponent,
    TextboxComponent,
    HeaderComponent,
    FooterComponent,
    HrComponent,
    FinalPreviewComponent,
    IdCardComponent,
    PagenotfoundComponent,
    NextbuttonComponent,
    PreviousbuttonComponent,
  ],
  imports: [
    MatSliderModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MaterialModule,
    MatDatepickerModule,
    MatNativeDateModule,
    HttpClientModule,
    MatIconModule,
    MatRadioModule,
    CommonModule,
    MatSelectModule,

  ],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
