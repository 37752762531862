import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { FormService } from '../../form.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-step-twelve-medical-history',
  templateUrl: './step-twelve-medical-history.component.html',
  styleUrls: ['./step-twelve-medical-history.component.scss'],
  providers: [FormGroupDirective]
})
export class StepTwelveMedicalHistoryComponent implements OnInit {
  showCheckboxes: boolean = false;

  @Input() stepForm!: FormGroup;
  userId: string;
  selectedNumbers: any[] = [];
  selectedNumber: any[] = [];
  showCheckboxe: boolean = false;
  studentPhotoImageUrl: any;
  studentPhotofilename: string;
  nextButtonClicked = false;
  selectedVitamins: any[] = [];
  showOtherInput: boolean;
  showVitaminCheckboxes: any;
  otherVitamin: string;

  constructor(
    private rootFormGroup: FormGroupDirective,
    private formservice: FormService,
    private fb: FormBuilder,
    private routes: Router
  ) {}

  ngOnInit(): void {
    this.userId = localStorage.getItem('userId');
    console.log(this.userId, 'fghjk');

    this.formservice.InfoseekMasterId(this.userId).subscribe(
      (data: any) => {
        const InfoseekMasterId = data.Result[0];
        this.studentPhotoImageUrl = InfoseekMasterId['upload_photo'];

        if (this.studentPhotoImageUrl) {
          const filename = new URL(this.studentPhotoImageUrl).pathname.split('/').pop();
          this.studentPhotofilename = filename; // Store filename for display
          console.log( this.studentPhotofilename,"image name");
        } else {
          console.warn('studentPhotoImageUrl is null or undefined.');
          this.studentPhotofilename = ''; // Clear placeholder if URL is unavailable
        }
        this.stepForm.patchValue({
          Does_the_student_have_any_medicalissue_In_The_Past:
            InfoseekMasterId['Does_the_student_have_any_medicalissue_In_The_Past'] || 'No',
          Does_the_student_have_any_medicalissue_In_The_Past_Yes:
            InfoseekMasterId['Does_the_student_have_any_medicalissue_In_The_Past_Yes'],
          Does_the_student_have_any_medicalissue_Currently:
            InfoseekMasterId['Does_the_student_have_any_medicalissue_Currently'] || 'No',
          Does_the_student_have_any_medicalissue_Currently_Yes:
            InfoseekMasterId['Does_the_student_have_any_medicalissue_Currently_Yes'],
            Past_Medication: InfoseekMasterId['Past_Medication'],
            Current_Medication: InfoseekMasterId['Current_Medication'],
            Any_Known_Allergies: InfoseekMasterId['Any_Known_Allergies'] || 'No',
            Any_Known_Allergies_yes: InfoseekMasterId['Any_Known_Allergies_yes'],

        });

        console.log('Form Values After Patching:', this.stepForm.value);

        this.toggleCheckboxes({
          value: InfoseekMasterId['Does_the_student_have_any_medicalissue_In_The_Past'],

        });

        this.toggleCheckboxe({
          value: InfoseekMasterId['Does_the_student_have_any_medicalissue_Currently'],

        });

        this.selectedNumbers = InfoseekMasterId['Does_the_student_have_any_medicalissue_In_The_Past_Yes']
          ? InfoseekMasterId['Does_the_student_have_any_medicalissue_In_The_Past_Yes'].split(',')
          : [];
        this.selectedNumber = InfoseekMasterId['Does_the_student_have_any_medicalissue_Currently_Yes']
          ? InfoseekMasterId['Does_the_student_have_any_medicalissue_Currently_Yes'].split(',')
          : [];
      },
      (error: any) => {
        console.error('Error fetching InfoseekMasterId:', error);
      }
    );

    this.stepForm = this.fb.group({
      Does_the_student_have_any_medicalissue_In_The_Past: ['', Validators.required],
      Does_the_student_have_any_medicalissue_In_The_Past_Yes: [''],
      Does_the_student_have_any_medicalissue_Currently: ['' ,Validators.required],
      Does_the_student_have_any_medicalissue_Currently_Yes: [''],
      Vitamin_deficiency: ['',Validators.required],
      Vitamin_deficiency_Other: ['',Validators.required],
      Past_Medication: ['',Validators.required],
      Current_Medication: ['',Validators.required],
      Any_Known_Allergies: ['',Validators.required],
      Any_Known_Allergies_yes: ['',Validators.required],
    });
  }

  toggleCheckboxes(event: any): void {
    this.showCheckboxes = event.value === 'Yes';
  }
  toggleCheckboxe(event: any): void {
    this.showCheckboxe = event.value === 'Yes';
  }

  numberssaved(event: any, value: string) {
    if (event.checked) {
      this.selectedNumbers = [...this.selectedNumbers, value];
    } else {
      this.selectedNumbers = this.selectedNumbers.filter((item: string) => item !== value);
    }
  
    // Check if 'Vitamin deficiency' is selected and toggle the vitamin deficiency checkboxes
    if (value === 'Vitamin deficiency') {
      this.toggleVitaminDeficiency(event);
    }
  
    console.log('Selected Numbers:', this.selectedNumbers);
  }
  onNextButtonClick() {
    this.nextButtonClicked = true;
    
  }
  toggleVitaminDeficiency(event: any) {
    this.showVitaminCheckboxes = event.checked;
    this.stepForm.get('Vitamin_deficiency')?.setValue(event.checked ? this.selectedVitamins.join(',') : '');
    if (!event.checked) {
      this.selectedVitamins = [];
      this.showOtherInput = false;
      this.stepForm.get('Vitamin_deficiency_Other')?.setValue('');
    }
  }

  vitaminChecked(event: any, value: string) {
    if (event.checked) {
      this.selectedVitamins = [...this.selectedVitamins, value];
    } else {
      this.selectedVitamins = this.selectedVitamins.filter((item: string) => item !== value);
    }

    console.log('selectedVitamins:', this.selectedVitamins);
  }
  toggleOtherInput(event: any) {
    this.showOtherInput = event.checked;
    if (!event.checked) {
      this.stepForm.get('Vitamin_deficiency_Other')?.setValue('');
    }
  }
  isCheckboxChecked(value: string): boolean {
    const formValue = this.stepForm
      .get('Does_the_student_have_any_medicalissue_In_The_Past_Yes')
      ?.value;
    return formValue ? formValue.includes(value) : false;
  }
  isCheckboxChecke(value: string): boolean {
    const formValue = this.stepForm
      .get('Does_the_student_have_any_medicalissue_Currently_Yes')
      ?.value;
    return formValue ? formValue.includes(value) : false;
  }
  numberssave(event: any, value: string) {
    if (event.checked) {
      this.selectedNumber = [...this.selectedNumber, value];
    } else {
      this.selectedNumber = this.selectedNumber.filter((item: string) => item !== value);
    }

    console.log('Selected Number:', this.selectedNumber);
  }
  goToPrevious(){
    this.routes.navigate(['/stepten'])

  }

  nextButtonClick(): void {
    this.formservice.incrementActiveStep();
  }
  onSubmit() {
    const sectionD = {
      Does_the_student_have_any_medicalissue_In_The_Past_Yes: this.selectedNumbers.join(',')|| null,
      Does_the_student_have_any_medicalissue_Currently_Yes: this.selectedNumber.join(',')|| null,
      Vitamin_deficiency: this.selectedVitamins && this.selectedVitamins.length > 0 ? this.selectedVitamins.join(',') : null,
      Vitamin_deficiency_Other: this.stepForm.get('Vitamin_deficiency_Other')?.value|| null,
      Does_the_student_have_any_medicalissue_In_The_Past:this.stepForm.get('Does_the_student_have_any_medicalissue_In_The_Past')?.value|| null,
      Does_the_student_have_any_medicalissue_Currently:this.stepForm.get('Does_the_student_have_any_medicalissue_Currently')?.value|| null,
      Past_Medication: this.stepForm.get('Past_Medication')?.value|| null,
      Current_Medication: this.stepForm.get('Current_Medication')?.value|| null,
      Any_Known_Allergies: this.stepForm.get('Any_Known_Allergies')?.value|| null,
      Any_Known_Allergies_yes: this.stepForm.get('Any_Known_Allergies_yes')?.value|| null,
    };

    console.log(sectionD, 'sectionA');

    const userId = localStorage.getItem('userId');

    this.formservice.updateUserRegL(userId, sectionD).subscribe(
      (data: any) => {
        if (data['Status'] === 200) {
          console.log('User data updated successfully');
          //this.routes.navigate(['/summary']);
          this.nextButtonClick();
        }
      },
      (error) => {
        console.error('Error updating user data:', error);
      }
    );
  }
}
