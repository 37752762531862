<div class="row">
  <app-header></app-header>
</div>

<!--Print ID Card Block-->
<div class="justify-content-center d-flex m-t-8 m-b-20">
  <div id="downloadIdCard" class="bigBox p-8">
    <div>
      <div class="rightBox">
        <div class="rightbox-content">
          <h2> UIN : {{UIN}}</h2>
          <span> DOB : {{ stepForm.get('Student_DOB').value }}</span>
          <span>Gender : {{ stepForm.get('Gender').value }}</span>
        </div>
        <div class="qrcode m-t-40">
        <p style="text-align: center;" class="m-t-40">
          <img [src]="stepForm.get('qrcode_image').value" alt="QR Code Image"  style="width: 50%; margin-top: 20%;">
        </p>
        <p style="text-align: center;margin-top: 16%;">
          <img src="/assets/images/logos/logo-maroon.svg">
        </p>
        </div>
      </div>
    </div>
    <div>
      <p style="text-align: center;">
        <img src="/assets/images/logos/logo-maroon.svg">
      </p>
      <div id="content">
        <div class="circle m-l-20">
          <img [src]="stepForm.get('upload_photo').value" alt="Profile Image" style="width: 50%; ">
        </div>
      </div>
      <div class="leftBox">
        <div class="leftbox-content">
          <h1 class="text-transform-capital boxcontent-h1">
          Student Name : {{ stepForm.get('Student_FirstName').value }}
          </h1>
          <span>Class Name : {{ stepForm.get('class_name').value }}</span>
          <span>Section Name : {{ stepForm.get('section_name').value }}</span>

        </div>
      </div>
    </div>
  </div>
  <div>
    <p>
      <!-- <button mat-icon-button aria-label="Download ID Card" (click)="downloadIdCard()"> -->
        <!-- <mat-icon>download</mat-icon>
      </button> -->
    </p>
  </div>
</div>

<!--Print Document Block-->

  <!-- <div style="border: 1px solid" class="row p-64 internalform"> -->

    <!-- <input type="button" value="Download" (click)="captureScreen()" /> -->
    <div id="downloadDocument" style="padding: 0 200px">

    <div class="col-md-12">
      <h1 class="align-items-center justify-content-center d-flex marroncolor">InfoSeek - Baseline Health Record</h1>
      <div class="p-r-48 p-l-48">
      </div>
    </div>

    <!--Student Personal Info-->
    <div class="col-md-12 p-r-64 p-l-64 m-b-48">
      <h3 class="align-items-center justify-content-center d-flex marroncolor">A.Student Personal Information</h3>
      <div class="row">
        <div class="col-md-12">
          <div class="row m-r-80 m-l-80">
            
            <div class="col-sm-8 d-flex align-items-center">
                <mat-label class="f-w-600">The following information is provided by :</mat-label>
            </div>
            <div class="col-sm-4">
              <span>{{ stepForm.get('following_information_providedby').value }}</span>
            </div>
            <div class="col-sm-10 d-flex align-items-center m-t-8" *ngIf="stepForm.get('If_Other').value">
              <mat-label class="f-w-600">Other :</mat-label>
              <span class="Other">{{ stepForm.get('If_Other').value }}</span>
          </div>
          <div class="col-sm-8 d-flex align-items-center m-t-8">
            <mat-label class="f-w-600">Student First Name :</mat-label>
          </div>
          <div class="col-sm-4 m-t-8">
            <span>{{ stepForm.get('Student_FirstName').value }}</span>
          </div>
          <div class="col-sm-8 d-flex align-items-center m-t-8">
            <mat-label class="f-w-600">Student Middle Name 1:</mat-label>
          </div>
          <div class="col-sm-4 m-t-8">
            <span>{{ stepForm.get('Student_MiddleName1').value }}</span>
          </div>
          <div class="col-sm-8 d-flex align-items-center m-t-8">
            <mat-label class="f-w-600">Student Middle Name 2 :</mat-label>
          </div>
          <div class="col-sm-4 m-t-8">
            <span>{{ stepForm.get('Student_MiddleName2').value }}</span>
          </div>
          <div class="col-sm-8 d-flex align-items-center m-t-8">
            <mat-label class="f-w-600">Student Last Name :</mat-label>
          </div>
          <div class="col-sm-4 m-t-8">
            <span>{{ stepForm.get('Student_LastName').value }}</span>
          </div>
          <div class="col-sm-8 d-flex align-items-center m-t-8">
            <mat-label class="f-w-600">DOB :</mat-label>
          </div>
          <div class="col-sm-4 m-t-8">
            <span>{{ stepForm.get('Student_DOB').value }}</span>
          </div>
          <div class="col-sm-8 d-flex align-items-center m-t-8">
            <mat-label class="f-w-600">Gender :</mat-label>
          </div>
          <div class="col-sm-4 m-t-8">
            <span>{{ stepForm.get('Gender').value }}</span>
          </div>
          <div class="col-sm-8 d-flex align-items-center m-t-8">
            <mat-label class="f-w-600">Blood Group :</mat-label>
          </div>
          <div class="col-sm-4 m-t-8">
            <span>{{ stepForm.get('Blood_group').value }}</span>
          </div>
          <div class="col-sm-8 d-flex align-items-center m-t-8">
            <mat-label class="f-w-600">Rh Factor :</mat-label>
          </div>
          <div class="col-sm-4 m-t-8">
            <span>{{ stepForm.get('Rh_Factor').value }}</span>
          </div>
          <div class="col-sm-8 d-flex align-items-center m-t-8">
            <mat-label class="f-w-600">Number of Sisters :</mat-label>
          </div>
          <div class="col-sm-4 m-t-8">
            <span>{{ stepForm.get('Number_Of_Sisters').value }}</span>
          </div>
          <div class="col-sm-8 d-flex align-items-center m-t-8">
            <mat-label class="f-w-600">Number of Brothers :</mat-label>
          </div>
          <div class="col-sm-4 m-t-8">
            <span>{{ stepForm.get('Number_Of_Brothers').value }}</span>
          </div>
          <div class="col-sm-8 d-flex align-items-center m-t-8">
            <mat-label class="f-w-600">Mother First Name :</mat-label>
          </div>
          <div class="col-sm-4 m-t-8">
            <span>{{ stepForm.get('Mothers_FirstName').value }}</span>
          </div>
          <div class="col-sm-8 d-flex align-items-center">
            <mat-label class="f-w-600">Mother Middle Name 1 :</mat-label>
          </div>
          <div class="col-sm-4">
            <span>{{ stepForm.get('Mothers_MiddleName1').value }}</span>
          </div>

          <div class="col-sm-8 d-flex align-items-center m-t-8">
            <mat-label class="f-w-600">Mother Middle Name 2 :</mat-label>
          </div>
          <div class="col-sm-4 m-t-8">
            <span>{{ stepForm.get('Mothers_MiddleName2').value }}</span>
          </div>

          <div class="col-sm-8 d-flex align-items-center m-t-8">
            <mat-label class="f-w-600">Mother Last Name :</mat-label>
          </div>
          <div class="col-sm-4  m-t-8">
            <span>{{ stepForm.get('Mothers_LastName').value }}</span>
          </div>
          <div class="col-sm-8 d-flex align-items-center m-t-8">
            <mat-label class="f-w-600">Mother Ethnicity :</mat-label>
          </div>
          <div class="col-sm-4  m-t-8">
            <span>{{ stepForm.get('Mothers_Ethnicity').value }}</span>
          </div>
          <div class="col-sm-10 d-flex align-items-center m-t-8" *ngIf="stepForm.get('Mothers_Ethnicity_If_Other').value">
            <mat-label class="f-w-600">Other :</mat-label>
            <span class="Other">{{ stepForm.get('Mothers_Ethnicity_If_Other').value }}</span>
        </div>
        <div class="col-sm-8 d-flex align-items-center m-t-8">
          <mat-label class="f-w-600">Father First Name :</mat-label>
        </div>
        <div class="col-sm-4  m-t-8">
          <span>{{ stepForm.get('Fathers_FirstName').value }}</span>
        </div>
        <div class="col-sm-8 d-flex align-items-center m-t-8">
          <mat-label class="f-w-600">Father Middle Name 1 :</mat-label>
        </div>
        <div class="col-sm-4  m-t-8">
          <span>{{ stepForm.get('Fathers_MiddleName1').value }}</span>
        </div>
        <div class="col-sm-8 d-flex align-items-center m-t-8">
          <mat-label class="f-w-600">Father Middle Name 2 :</mat-label>
        </div>
        <div class="col-sm-4  m-t-8">
          <span>{{ stepForm.get('Fathers_MiddleName2').value }}</span>
        </div>
        <div class="col-sm-8 d-flex align-items-center m-t-8">
          <mat-label class="f-w-600">Father Last Name :</mat-label>
        </div>
        <div class="col-sm-4  m-t-8">
          <span>{{ stepForm.get('Fathers_LastName').value }}</span>
        </div>
        <div class="col-sm-8 d-flex align-items-center m-t-8">
          <mat-label class="f-w-600">Father Ethnicity :</mat-label>
        </div>
        <div class="col-sm-4  m-t-8">
          <span>{{ stepForm.get('Fathers_Ethnicity').value }}</span>
        </div>
        <div class="col-sm-10 d-flex align-items-center m-t-8" *ngIf="stepForm.get('Fathers_Ethnicity_If_Other').value">
          <mat-label class="f-w-600">Other :</mat-label>
          <span class="Other">{{ stepForm.get('Fathers_Ethnicity_If_Other').value }}</span>
      </div>
       
          </div>
        </div>
      </div>
    </div>

    <!--Parent Info-->
    <div class="col-md-12 p-r-64 p-l-64">
      <h3 class="align-items-center justify-content-center d-flex marroncolor">B. Health Insurance Information</h3>
      <div class="row">
        <div class="col-md-12">
          <div class="row m-r-80 m-l-80">
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600"> Does the Student have health insurance? :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Do_you_have_health_insurance').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600"> Health insurance :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Medical_Aid').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600"> Policy # :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Policy_Card').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600"> Policy Expiry Date :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Expiry_Date').value }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!--Doctor Info-->
    <div class="col-md-12 p-r-64 p-l-64 m-b-48">
      <h3 class="align-items-center justify-content-center d-flex marroncolor">C. Address Information</h3>
      <div class="row">
        <div class="col-md-12">
          <div class="row m-r-80 m-l-80">
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600"> Building Name :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Building_Name').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600"> Apartment/Villa # :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Apartment_Villa_No').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600"> Emirates/Street :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Street_No').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600"> Area :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Area').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600"> City :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('City').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600"> Country :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Country').value }}</span>
            </div>

            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600"> State :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('State').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600"> Zip Code :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Zip_Code').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600"> Emergency Mobile Number Registered With School :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Emergency_MobileNumber_Registered_with_school').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600">Alternative Mobile Number :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Alternate_MobileNumber').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600"> Belongs To :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Belongs_To').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600"> Email Registered With School :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Email_Registered_With_School').value }}</span>
            </div>
              </div>
        </div>
      </div>
    </div>

    <!--School Info-->
    <div class="col-md-12 p-r-64 p-l-64 m-b-48">
      <h3 class="align-items-center justify-content-center d-flex marroncolor">D. Emergency Contact</h3>
      <div class="row">
        <div class="col-md-12">
          <div class="row m-r-80 m-l-80">
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600"> Primary Contact Belongs to :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Primary_Contact_Belongs_To').value }}</span>
            </div>
            <div class="col-sm-12 d-flex align-items-center m-t-8" *ngIf="stepForm.get('Primary_Contact_Belongs_To_Other').value">
              <mat-label class="f-w-600">Other :</mat-label>
              <span class="Other">{{ stepForm.get('Primary_Contact_Belongs_To_Other').value }}</span>
          </div>
          <div class="col-sm-8 d-flex align-items-center m-t-8">
            <mat-label class="f-w-600"> Primary Contact # :</mat-label>
          </div>
          <div class="col-sm-4  m-t-8">
            <span>{{ stepForm.get('Primary_Contact').value }}</span>
          </div>
          <div class="col-sm-8 d-flex align-items-center m-t-8">
            <mat-label class="f-w-600"> Full Name of Primary Contact :</mat-label>
          </div>
          <div class="col-sm-4  m-t-8">
            <span>{{ stepForm.get('Primary_Contact_Full_Name').value }}</span>
          </div>
          <div class="col-sm-8 d-flex align-items-center m-t-8">
            <mat-label class="f-w-600"> Secondary Contact  :</mat-label>
          </div>
          <div class="col-sm-4  m-t-8">
            <span>{{ stepForm.get('Secondary_Contact').value }}</span>
          </div>
          <div class="col-sm-8 d-flex align-items-center m-t-8">
            <mat-label class="f-w-600"> Secondary Contact Belongs to  :</mat-label>
          </div>
          <div class="col-sm-4  m-t-8">
            <span>{{ stepForm.get('Secondary_Contact_Belongs_To').value }}</span>
          </div>
          <div class="col-sm-12 d-flex align-items-center m-t-8" *ngIf="stepForm.get('Secondary_Contact_Belongs_To_Other').value">
            <mat-label class="f-w-600">Other :</mat-label>
            <span class="Other">{{ stepForm.get('Secondary_Contact_Belongs_To_Other').value }}</span>
        </div>
        <div class="col-sm-8 d-flex align-items-center m-t-8">
          <mat-label class="f-w-600"> Full Name of Secondary Contact :</mat-label>
        </div>
        <div class="col-sm-4  m-t-8">
          <span>{{ stepForm.get('Secondary_Contact_Full_Name').value }}</span>
        </div>
        <div class="col-sm-8 d-flex align-items-center m-t-8">
          <mat-label class="f-w-600"> Family Physician Name :</mat-label>
        </div>
        <div class="col-sm-4  m-t-8">
          <span>{{ stepForm.get('Doctor_Contact_Number').value }}</span>
        </div>
        <div class="col-sm-8 d-flex align-items-center m-t-8">
          <mat-label class="f-w-600"> Physician Contact #  :</mat-label>
        </div>
        <div class="col-sm-4  m-t-8">
          <span>{{ stepForm.get('Doctor_Contact_Number').value }}</span>
        </div>
          </div>
        </div>
      </div>
    </div>

    

    <!--Basic Lifestyle Info-->
    <div class="col-md-12 p-r-64 p-l-64 m-b-48">
      <h3 class="align-items-center justify-content-center d-flex marroncolor">E. Basic Life Style</h3>
      <div class="row">
        <div class="col-md-12">
          <div class="row m-r-80 m-l-80">
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600"> What kind of Recreational Activity does the Student enjoy the most?  :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('What_typeof_recreational_activity_doesthestudentenjoy').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600"> Which of the following Activities does the Student enjoy?  :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Which_ofthe_following_activities_doesthestudentenjoy').value }}</span>
            </div>
            <div class="col-sm-10 d-flex align-items-center m-t-8" *ngIf="stepForm.get('Activites_Other').value">
              <mat-label class="f-w-600"> Other:</mat-label>
              <span class="Other">{{ stepForm.get('Activites_Other').value }}</span>
          </div>
          <div class="col-sm-8 d-flex align-items-center m-t-8">
            <mat-label class="f-w-600"> Is the Student a member of any Sports team at School?"
               :</mat-label>
          </div>
          <div class="col-sm-4  m-t-8">
            <span>{{ stepForm.get('Isthe_student_memberofasports_teamatschool').value }}</span>
          </div>
          <div class="col-sm-8 d-flex align-items-center m-t-8">
            <mat-label class="f-w-600"> If yes, which one ?  :</mat-label>
          </div>
          <div class="col-sm-4  m-t-8">
            <span>{{ stepForm.get('Isthe_student_memberofasports_teamatschool_Yes').value }}</span>
          </div>
          <div class="col-sm-8 d-flex align-items-center m-t-8">
            <mat-label class="f-w-600"> Do you have Pets at home?  :</mat-label>
          </div>
          <div class="col-sm-4  m-t-8">
            <span>{{ stepForm.get('Do_you_have_pets_at_home').value }}</span>
          </div>
          <div class="col-sm-8 d-flex align-items-center m-t-8">
            <mat-label class="f-w-600">Pet Animal :</mat-label>
          </div>
          <div class="col-sm-4  m-t-8">
            <span>{{ stepForm.get('Do_you_have_pets_at_home_Yes_Animal').value }}</span>
          </div>
          <div class="col-sm-8 d-flex align-items-center m-t-8">
            <mat-label class="f-w-600"> Duration(months)  :</mat-label>
          </div>
          <div class="col-sm-4  m-t-8">
            <span>{{ stepForm.get('Do_you_have_pets_at_home_Yes_Duration').value }}</span>
          </div>
            <div class="col-sm-4 d-flex align-items-center m-t-8">
              <mat-label></mat-label>
            </div>
          
        </div>
      </div>
    </div>
  </div>

    <!--Dietary Habity Info-->
    <div class="col-md-12 p-r-64 p-l-64 m-b-48">
      <h3 class="align-items-center justify-content-center d-flex marroncolor">F. Dietary Habits</h3>
      <div class="row">
        <div class="col-md-12">
          <div class="row m-r-80 m-l-80">
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600"> Usual number of Meals per Day (Eg: Breakfast, Lunch, Dinner) :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Usual_numberof_mealsday').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600"> Usual number of Snacks per Day :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Usual_numberof_snacksday').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600"> Average total Fluid intake per Day (in mL) :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('What_is_the_students_average_fluid_intake').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600">Type of Meal :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Type_of_meal').value }}</span>
            </div>
            <div class="col-sm-10 d-flex align-items-center m-t-8" *ngIf="stepForm.get('Type_of_meal_Other').value">
              <mat-label class="f-w-600"> Other:</mat-label>
              <span class="Other">{{ stepForm.get('Type_of_meal_Other').value }}</span>
          </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600">Does the Student have Breakfast regularly? :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Does_the_student_have_breakfast_regularly').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600"> Does the Student have regular Meals at School? :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Does_the_student_have_regular_meal_at_school').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600"> If Yes, where does the Student mostly get the meal? :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Student_If_Yes_where_does_it_come_from').value }}</span>
            </div>
            <div class="col-sm-10 d-flex align-items-center m-t-8" *ngIf="stepForm.get('Student_If_Other').value">
              <mat-label class="f-w-600"> Other:</mat-label>
              <span class="Other">{{ stepForm.get('Student_If_Other').value }}</span>
          </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600"> Is the Student intolerant of any Food or Food Group? :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Is_the_student_intolerant_to_any_food_group').value }}</span>
            </div>
            <div class="col-sm-10 d-flex align-items-center m-t-8" *ngIf="stepForm.get('Food_Group_Other').value">
              <mat-label class="f-w-600"> Other:</mat-label>
              <span class="Other">{{ stepForm.get('Food_Group_Other').value }}</span>
          </div>


          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="p-r-48 p-l-48">
      </div>
    </div>

    <!--Sleep Pattern & Quality Info-->
    <div class="col-md-12 p-r-64 p-l-64 m-b-48">
      <h3 class="align-items-center justify-content-center d-flex marroncolor">G. Sleep Pattern & Quality</h3>
      <div class="row">
        <div class="col-md-12">
          <div class="row m-r-80 m-l-80">
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600"> on an average is the Student fresh and relaxed after Night's sleep? :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('On_an_average_isthe_student_freshandrelaxed_night_sleep').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600"> Does the Student have any sleep related issues(Tick all that are applicable) :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Does_the_student_have_any_sleep_related_issues').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600"> Please provide details about Student have any sleep related issues :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Does_the_student_have_any_sleep_related_issues_Yes').value }}</span>
            </div>
            <div class="col-sm-10 d-flex align-items-center m-t-8" *ngIf="stepForm.get('Sleep_related_issues_Other').value">
              <mat-label class="f-w-600"> Other:</mat-label>
              <span class="Other">{{ stepForm.get('Sleep_related_issues_Other').value }}</span>
          </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600"> What is the Student's average length of sleep per Night? :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('What_is_the_students_average_length_of_sleep_per_night').value }}</span>
            </div>
            <!-- <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600"> What is the Student's Nap Cycle during the Day? :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('What_is_the_students_average_length_of_sleep_per_night').value }}</span>
            </div> -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600">Nap :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('What_is_the_students_nap_cycle_during_day_Nap').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600">Duration :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('What_is_the_students_nap_cycle_during_day_Nap_Duration').value }}</span>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="p-r-48 p-l-48">
      </div>
    </div>

    <!--Personality & Social Interaction Info-->
    <div class="col-md-12 p-r-64 p-l-64 m-b-48">
      <h3 class="align-items-center justify-content-center d-flex marroncolor">H. Personality & Social Interaction</h3>
      <div class="row">
        <div class="col-md-12">
          <div class="row m-r-80 m-l-80">
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600">How would you best describe the Social Personality of the Student? :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('How_would_you_describe_the_social_personality_ofthe_student').value }}</span>
            </div>
            <div class="col-sm-10 d-flex align-items-center m-t-8" *ngIf="stepForm.get('Social_personality_Other').value">
              <mat-label class="f-w-600"> Other:</mat-label>
              <span class="Other">{{ stepForm.get('Social_personality_Other').value }}</span>
          </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600">Specify if the Student has any irrational Fears or Phobias :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Specify_if_the_student_has_any_irrational_fears_phobias').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600">How would you rate the Student Self Image/Self Worth? :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('How_would_you_rate_the_students_self_image_self_worth').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600">How would you rate the Student's Cooperation in various household chores :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('How_would_you_rate_students_cooperation_in_housedhold_chores').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600">How would you describe the Student'S reaction to Change? :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('How_would_you_best_describe_the_student_reaction_to_change').value }}</span>
            </div>
            <div class="col-sm-10 d-flex align-items-center m-t-8" *ngIf="stepForm.get('Student_reaction_Other').value">
              <mat-label class="f-w-600"> Other:</mat-label>
              <span class="Other">{{ stepForm.get('Student_reaction_Other').value }}</span>
          </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600">How would you describe the Student's relationship with other Students? :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('How_would_you_describe_student_rs_with_other_students').value }}</span>
            </div>
            <div class="col-sm-10 d-flex align-items-center m-t-8" *ngIf="stepForm.get('How_would_you_describe_student_rs_with_other_students_Other').value">
              <mat-label class="f-w-600"> Other:</mat-label>
              <span class="Other">{{ stepForm.get('How_would_you_describe_student_rs_with_other_students_Other').value }}</span>
          </div>
          <div class="col-sm-8 d-flex align-items-center m-t-8">
            <mat-label class="f-w-600">Does the Student have any significantly close Friends? :</mat-label>
          </div>
          <div class="col-sm-4  m-t-8">
            <span>{{ stepForm.get('Does_the_student_have_any_significantly_close_friends').value }}</span>
          </div>
          <div class="col-sm-8 d-flex align-items-center m-t-8">
            <mat-label class="f-w-600">How many boys :</mat-label>
          </div>
          <div class="col-sm-4  m-t-8">
            <span>{{ stepForm.get('student_close_friends_yes_how_many_boys').value }}</span>
          </div>
          <div class="col-sm-8 d-flex align-items-center m-t-8">
            <mat-label class="f-w-600">How many girls :</mat-label>
          </div>
          <div class="col-sm-4  m-t-8">
            <span>{{ stepForm.get('student_close_friends_yes_how_many_girls').value }}</span>
          </div>
           </div>
          </div>
      </div>
    </div>



    <!--Lite At School Info-->
    <div class="col-md-12 p-r-64 p-l-64 m-b-48">
      <h3 class="align-items-center justify-content-center d-flex marroncolor">I. Life At School</h3>
      <div class="row">
        <div class="col-md-12">
          <div class="row m-r-80 m-l-80">
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600">The Student goes to School :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('The_student_goes_to_school').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600">What is the Student's general Opinion of School? :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('What_is_student_general_opinion_of_school').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600">How would you rate the Student's overall Attendance at School? :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('How_would_you_rate_student_overall_attendance_at_school').value }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--General History Info-->
    <div class="col-md-12 p-r-64 p-l-64 m-b-48">
      <h3 class="align-items-center justify-content-center d-flex marroncolor">J. General History</h3>
      <div class="row">
        <div class="col-md-12">
          <div class="row m-r-80 m-l-80">
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600">Do you have Concerns with any of the following with respect to the Student? (Tick all that applicable) :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Do_you_have_concerns_following_respect_tothe_student').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600">How would you rate Student's overall Physical Health? :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('How_would_you_rate_the_student_overall_physical_health').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600">Is the student's school performance significantly dissatisfied by any of the following? :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Isthe_student_significantly_dissatisfied_byany_following').value }}</span>
            </div> 
            <div class="col-sm-10 d-flex align-items-center m-t-8" *ngIf="stepForm.get('Student_dissatisfied_Other').value">
              <mat-label class="f-w-600"> Other:</mat-label>
              <span class="Other">{{ stepForm.get('Student_dissatisfied_Other').value }}</span>
          </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600">Is the Students School performance affected by any following :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Isthe_students_school_performance_affected_by_any_following').value }}</span>
            </div> 
  
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 p-r-64 p-l-64 m-b-48">
      <h3 class="align-items-center justify-content-center d-flex marroncolor">K. Family History</h3>
      <div class="row">
        <div class="col-md-12">
          <div class="row m-r-80 m-l-80">
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600">> Any Illness/ Condition among immediate Members of the Student Family(grandparents, parents, siblings, aunt, uncle) :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Any_Ongoing_Illnesscondition_membersofthe_students_family').value }}</span>
            </div>
            <div class="col-sm-10 d-flex align-items-center m-t-8" *ngIf="stepForm.get('Any_students_family_Other').value">
              <mat-label class="f-w-600"> Other:</mat-label>
              <span class="Other">{{ stepForm.get('Any_students_family_Other').value }}</span>
          </div> 
          </div>
        </div>
      </div>
    </div>

   

    <div class="col-md-12 p-r-64 p-l-64 m-b-48">
      <h3 class="align-items-center justify-content-center d-flex marroncolor">L. Medical History</h3>
      <div class="row">
        <div class="col-md-12">
          <div class="row m-r-80 m-l-80">
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600">Did the Student have any significant medical issue currently? (Tick all that are applicable) :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Does_the_student_have_any_medicalissue_Currently').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600">Please provide details about  Did the Student have any significant medical issue currently? :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Does_the_student_have_any_medicalissue_Currently_Yes').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600">Current Medication, if any:</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Current_Medication').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600">Did the Student have any significant medical issue in the past? (Tick all that are applicable) :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Does_the_student_have_any_medicalissue_In_The_Past').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600">Please provide details about  Did the Student have any significant medical issue past? :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Does_the_student_have_any_medicalissue_In_The_Past_Yes').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600">Past Medication, if any :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Past_Medication').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600">Any known Allergies? :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Any_Known_Allergies').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <mat-label class="f-w-600">If yes Please provide details about your allergies :</mat-label>
            </div>
            <div class="col-sm-4  m-t-8">
              <span>{{ stepForm.get('Any_Known_Allergies_yes').value }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="nextbuttoncolor justify-content-center d-flex" style="height: 50px;">
        <mat-card-content class="m-t-20 whitecolor justify-content-center d-flex">Novel Implementation of a Vision for an Integrated System of Healthcare</mat-card-content>
      </div>
    </div>
    </div>
  <!-- </div> -->

 
<div class="col-md-12 m-t-20 p-b-40">
  <div class="justify-content-center d-flex" style="height: 50px;">
    <button mat-raised-button class="sharebutton m-r-20"><mat-icon>share</mat-icon>Share</button>
    <button mat-raised-button class="downloadbutton" (click)="captureScreen()"><mat-icon>download</mat-icon>Download</button>
  </div>
</div>
    