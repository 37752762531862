import { Component, OnInit,Input } from '@angular/core';
import { AbstractControl, FormControl, FormGroupDirective } from '@angular/forms';
import { FormService } from '../../form.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
@Component({
  selector: 'app-step-six',
  templateUrl: './step-six.component.html',
  styleUrls: ['./step-six.component.scss'],
  providers: [FormGroupDirective]  // Add this line

})
export class StepSixComponent implements OnInit{
  @Input() stepForm!: FormGroup;
  formService: any;
  userId: string;
  ifyes:boolean=false;
  typeofmeal:boolean=false;
  selectedNumbers: any[] = [];
  studentPhotoImageUrl: any;
  studentPhotofilename: string;
  maxChars = 100;
  showOtherInput: boolean=false;



  constructor(
    private rootFormGroup: FormGroupDirective,
    private formservice: FormService ,
    private fb: FormBuilder,
    private routes:Router
  ){}
  ifyeschange(event: any) {
    this.ifyes = event.value === 'Other';
  
    // If the input field is not shown, clear the value
    if (!this.ifyes) {
      this.stepForm.get('Student_If_Other').setValue('');
    }
  }
  typeofmealchange(event: any) {
    this.typeofmeal = event.value === 'Other';
  
    // If the input field is not shown, clear the value
    if (!this.typeofmeal) {
      this.stepForm.get('Type_of_meal_Other').setValue('');
    }
  }
  nextButtonClick(): void {
    this.formservice.incrementActiveStep();
  }
  goToPrevious(){
    this.routes.navigate(['/stepfive'])
  }
  numberssaved(event: any, value: string) {
    if (event.checked) {
      // Add the value to the array if the checkbox is checked
      this.selectedNumbers = [...this.selectedNumbers, value];
    } else {
      // Remove the value from the array if the checkbox is unchecked
      this.selectedNumbers = this.selectedNumbers.filter((item: string) => item !== value);
    }
    this.toggleOtherInput();

    console.log('Selected Numbers:', this.selectedNumbers);
  }
  toggleOtherInput(){
    console.log("select");
    const activitiesOtherChecked = this.selectedNumbers.includes('Other');
  
    if (activitiesOtherChecked) {
      this.showOtherInput = true; // Show the input field if 'Other' is included in selectedNumbers
    } else {
      this.showOtherInput = false; // Hide the input field if 'Other' is not included in selectedNumbers
      this.stepForm.get('Food_Group_Other')?.setValue(''); // Clear the input field
    }
  }
  isCheckboxChecked(value: string): boolean {
    const formValue = this.stepForm.get('Is_the_student_intolerant_to_any_food_group')?.value;
    return formValue ? formValue.includes(value) : false;
  }
  sanitizeInput(event: any): void {
    // Remove non-numeric characters from the input value
    const sanitizedValue = event.target.value.replace(/[^0-9]/g, '');
    // Update the form control with the sanitized value
    this.stepForm.get('Usual_numberof_mealsday').setValue(sanitizedValue, { emitEvent: false });
  }
  snackssanitizeInput(event: any): void {
    // Remove non-numeric characters from the input value
    const sanitizedValue = event.target.value.replace(/[^0-9]/g, '');
    // Update the form control with the sanitized value
    this.stepForm.get('Usual_numberof_snacksday').setValue(sanitizedValue, { emitEvent: false });
  }
  fluidsanitizeInput(event: any): void {
    // Remove non-numeric characters from the input value
    const sanitizedValue = event.target.value.replace(/[^0-9]/g, '');
    // Update the form control with the sanitized value
    this.stepForm.get('What_is_the_students_average_fluid_intake').setValue(sanitizedValue, { emitEvent: false });
  }
  ngOnInit(): void {
    this.userId = localStorage.getItem('userId');
    console.log(this.userId, "fghjk");
  
    // Create the form group first
    this.stepForm = this.fb.group({
      Usual_numberof_mealsday: ['', Validators.required],
      Usual_numberof_snacksday: ['', Validators.required],
      What_is_the_students_average_fluid_intake: ['', Validators.required],
      Type_of_meal: ['', Validators.required],
      Type_of_meal_Other: ['', Validators.required],
      Does_the_student_have_breakfast_regularly: ['', Validators.required],
      Does_the_student_have_regular_meal_at_school: ['', Validators.required],
      Student_If_Yes_where_does_it_come_from: ['', Validators.required],
      Student_If_Other: ['', Validators.required],
      Is_the_student_intolerant_to_any_food_group: [''],
      Food_Group_Other: ['', Validators.required],
      
    });
  
    // Fetch additional information based on userId
    this.formservice.InfoseekMasterId(this.userId).subscribe(
      (data: any) => {
        const InfoseekMasterId = data?.Result?.[0]; // Handle null or undefined
        this.studentPhotoImageUrl = InfoseekMasterId['upload_photo'];

        if (this.studentPhotoImageUrl) {
          const filename = new URL(this.studentPhotoImageUrl).pathname.split('/').pop();
          this.studentPhotofilename = filename; // Store filename for display
          console.log( this.studentPhotofilename,"image name");
        } else {
          console.warn('studentPhotoImageUrl is null or undefined.');
          this.studentPhotofilename = ''; // Clear placeholder if URL is unavailable
        }
        if (InfoseekMasterId) {
          // Patch values after the form group is created
          this.stepForm.patchValue({
            Usual_numberof_mealsday: InfoseekMasterId['Usual_numberof_mealsday'],
            Usual_numberof_snacksday: InfoseekMasterId['Usual_numberof_snacksday'],
            What_is_the_students_average_fluid_intake: InfoseekMasterId['What_is_the_students_average_fluid_intake'],
            Type_of_meal: InfoseekMasterId['Type_of_meal'],
            Type_of_meal_Other: InfoseekMasterId['Type_of_meal_Other'],
            Does_the_student_have_breakfast_regularly: InfoseekMasterId['Does_the_student_have_breakfast_regularly'] ||'No',
            Does_the_student_have_regular_meal_at_school: InfoseekMasterId['Does_the_student_have_regular_meal_at_school'] ||'No',
            Student_If_Yes_where_does_it_come_from: InfoseekMasterId['Student_If_Yes_where_does_it_come_from'],
            Student_If_Other: InfoseekMasterId['Student_If_Other'],
            Is_the_student_intolerant_to_any_food_group: InfoseekMasterId['Is_the_student_intolerant_to_any_food_group'] ,
            Food_Group_Other: InfoseekMasterId['Food_Group_Other'],
          });

          console.log("Form Values After Patching:", this.stepForm.value);

          this.selectedNumbers = InfoseekMasterId['Is_the_student_intolerant_to_any_food_group']
          
      ? InfoseekMasterId['Is_the_student_intolerant_to_any_food_group'].split(',')
      : [];
        }
        this.toggleOtherInput();

      },
      (error: any) => {
        console.error('Error fetching InfoseekMasterId:', error);
      }
    );
  
  
}
onSubmit() {

  const sectionD={
    Usual_numberof_mealsday:this.stepForm.get('Usual_numberof_mealsday')?.value|| null,
    Usual_numberof_snacksday:this.stepForm.get('Usual_numberof_snacksday')?.value|| null,
    What_is_the_students_average_fluid_intake:this.stepForm.get('What_is_the_students_average_fluid_intake')?.value|| null,
    Type_of_meal:this.stepForm.get('Type_of_meal')?.value|| null,
    Type_of_meal_Other:this.stepForm.get('Type_of_meal_Other')?.value|| null,
    Does_the_student_have_breakfast_regularly:this.stepForm.get('Does_the_student_have_breakfast_regularly')?.value|| null,
    Does_the_student_have_regular_meal_at_school:this.stepForm.get('Does_the_student_have_regular_meal_at_school')?.value|| null,
    Student_If_Yes_where_does_it_come_from:this.stepForm.get('Student_If_Yes_where_does_it_come_from')?.value|| null,
    Student_If_Other:this.stepForm.get('Student_If_Other')?.value|| null,
    Is_the_student_intolerant_to_any_food_group:this.selectedNumbers.join(',') || null,
    Food_Group_Other:this.stepForm.get('Food_Group_Other')?.value|| null,
}
console.log(sectionD,"sectionA")

const userId = localStorage.getItem('userId');
const formData = new FormData();

// Append form values to formData

this.formservice.updateUserRegF(userId, sectionD).subscribe(
  (data: any) => {
    if (data['Status'] === 200) {
      console.log('User data updated successfully');
      //this.routes.navigate(['/stepseven'])
      this.nextButtonClick();
    }
  },
  (error) => {
    console.error('Error updating user data:', error);
  }
);
}
}


