<div class="row">
  <div class="col-md-12 p-r-32 p-l-32">
    <ng-container [formGroup]="stepForm">
      <div class="step-one boxshadow-form">
        <!--Header Block starts here Designed by Krishna Kanth on 18th Jan 2024-->
        <div class="title text-center p-t-20 d-flex justify-content-center">
          <p class="marroncolor f-w-500 m-t-24 f-s-24 m-t-24 d-flex align-items-center">C. Address Information</p>
          <label for="digitalsigninput" class="uploadedimages">
            <img class="uploadedimage" style="width: 100px;" *ngIf="studentPhotoImageUrl" [src]="studentPhotoImageUrl">
            <div *ngIf="!studentPhotoImageUrl">
            <img mat-card-image src="/assets/images/svgs/upload-image.svg" alt="Upload Icon" />
            </div>
            </label>
        </div>
        <!--Header Block starts here Designed by Krishna Kanth on 18th Jan 2024-->

        <mat-card-content>
          <form [formGroup]="stepForm" (ngSubmit)="onSubmit()">
            <div id="scrollbar" class="internalform m-t-40">
              <div class="row">
                <div class="col-lg-12">
                  <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16 formlabel">Country<sup class="required-field"></sup></mat-label>
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-select placeholder="Country" formControlName="Country" (selectionChange)="onCountrySelected()">
                      <mat-option *ngFor="let country of CountryList" [value]="country">{{ country }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div *ngIf="nextButtonClicked && stepForm.get('Country').hasError('required')" class="error-message">
                    Country is required
                  </div>
                </div>
                <div class="col-lg-12">
                  <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16 formlabel">Emirates/State<sup class="required-field"></sup></mat-label>
                  <!-- <mat-form-field appearance="outline" class="w-100">
                    <mat-select placeholder="state" formControlName="State">

                    <mat-option *ngFor="let State of statesList" [value]="State">{{ State }}</mat-option>
                    </mat-select>
                  </mat-form-field> -->
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="City" formControlName="State" >
                  </mat-form-field>
                  <div *ngIf="nextButtonClicked && stepForm.get('State').hasError('required')" class="error-message">
                    State is required
                  </div>
                </div>
                <div class="col-lg-12">
                  <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16 formlabel">City<sup class="required-field"></sup></mat-label>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="City" formControlName="City" >
                  </mat-form-field>
                  <div *ngIf="nextButtonClicked && stepForm.get('City').hasError('required')" class="error-message">
                    City is required
                  </div>
                </div>
                <div class="col-lg-12">
                  <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16 formlabel">Zip Code<sup class="required-field"></sup></mat-label>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Zip Code"  formControlName="Zip_Code">
                  </mat-form-field>
                  <div *ngIf="nextButtonClicked && stepForm.get('Zip_Code').hasError('required')" class="error-message">
                    Zip Code is required
                  </div>
                </div>

                <div class="col-lg-12">
                  <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16 formlabel">Area<sup class="required-field"></sup></mat-label>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Area" formControlName="Area" >
                  </mat-form-field>
                  <div *ngIf="nextButtonClicked && stepForm.get('Area').hasError('required')" class="error-message">
                    Area is required
                  </div>
                </div>

                <div class="col-lg-12">
                  <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16 formlabel"> Street<sup class="required-field"></sup></mat-label>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Street" formControlName="Street_No" >
                  </mat-form-field>
                  <div *ngIf="nextButtonClicked && stepForm.get('Street_No').hasError('required')" class="error-message">
                    Street is required
                  </div>
                </div>
                <div class="col-lg-12">
                  <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16 formlabel">Building Name<sup class="required-field"></sup></mat-label>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Building Name" formControlName="Building_Name" >
                  </mat-form-field>
                  <div *ngIf="nextButtonClicked && stepForm.get('Building_Name').hasError('required')" class="error-message">
                    Building Name is required
                  </div>
                </div>
                
                <div class="col-lg-12">
                  <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16 formlabel">Apartment/Villa #<sup class="required-field"></sup></mat-label>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Apartment/Villa" formControlName="Apartment_Villa_No" >
                  </mat-form-field>
                  <div *ngIf="nextButtonClicked && stepForm.get('Apartment_Villa_No').hasError('required')" class="error-message">
                    Apartment/Villa is required
                  </div>
                </div>

                <div class="col-lg-12">
                  <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16 formlabel Emergency">Emergency Mobile Number Registered With School<sup class="required-field"></sup></mat-label>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Emergency Mobile Number" maxlength="15" formControlName="Emergency_MobileNumber_Registered_with_school" pattern="[0-9]*">
                  </mat-form-field>
                </div>
                <div class="error-message" *ngIf="stepForm.get('Emergency_MobileNumber_Registered_with_school').hasError('pattern')">
                  Please enter a valid mobile number (up to 15 digits).
                </div>
                <div *ngIf="nextButtonClicked && stepForm.get('Emergency_MobileNumber_Registered_with_school').hasError('required')" class="error-message">
                  Emergency Mobile Number Registered With School is required
                </div>
                <div class="col-lg-12">
                  <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16 formlabel">Alternative Mobile Number #<sup class="required-field"></sup></mat-label>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Alternative Mobile Number" maxlength="15" formControlName="Alternate_MobileNumber"  pattern="[0-9]*">
                  </mat-form-field>
                 
                </div>
                <div class="error-message" *ngIf="stepForm.get('Alternate_MobileNumber').hasError('pattern')">
                  Please enter a valid mobile number (up to 15 digits).
                </div>

                <div class="col-lg-12" *ngIf="stepForm.get('Alternate_MobileNumber').value">
                  <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16 formlabel">Belongs To<sup class="required-field"></sup></mat-label>
                  <mat-radio-group id="nradiobutton" aria-label="Select an option" formControlName="Belongs_To">
                    <mat-radio-button value="Mother">Mother</mat-radio-button>
                    <mat-radio-button value="Father">Father</mat-radio-button>
                    <mat-radio-button value="Guardian">Guardian</mat-radio-button>
                  </mat-radio-group>
                
                  <div *ngIf="nextButtonClicked && stepForm.get('Belongs_To').hasError('required')" class="error-message">
                    Belongs_To is required
                  </div>
                </div>

                <div class="col-lg-12 m-t-10">
                  <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16 formlabel">Email Registered With School<sup class="required-field"></sup></mat-label>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Email Registered With School" formControlName="Email_Registered_With_School" readonly>
                  </mat-form-field>
                  <div *ngIf="nextButtonClicked && stepForm.get('Email_Registered_With_School').hasError('required')" class="error-message">
                    Email Registered With School is required
                  </div>
                </div>
              </div>
            </div>

            <div class="row justify-content-end m-t-32 float-right">
              <div class="col-lg-12 p-r-64">
                <app-previousbutton></app-previousbutton>
                <app-nextbutton (click)="onNextButtonClick()"></app-nextbutton>
              </div>
            </div>
          </form>


        </mat-card-content>
      </div>
    </ng-container>
  </div>
</div>
