import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { FormService } from '../../form.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-step-student-personal-info',
  templateUrl: './step-one-personal-details.component.html',
  styleUrls: ['./step-one-personal-details.component.scss']
})

export class StepOneStudentPersonalInfoComponent implements OnInit {
  stepForm: FormGroup;

  userId: string;
  studentPhotofilename: string = '';
  studentPhotoImageUrl: string = '';
  studentPersonalInformation: string = '';
  studentPersonalInformationothers: string = '';
  profileimage: any;
  selectedUserProfile:any | null = 'null';
  studentPhoto:any | null = 'null';
  digitalSignImageUrl: string | null = null;
  selectedNumbers: any[] = [];
  selectedImageName: string = ''; 
  fileTypeError: boolean = false;
  selectedImage: any;
  @Input() formGroupName!: string;
  errorMessage: string;
  Upload_Your_Sign: File;
  picerror: string;
  message: any;
  maxChars = 100;
  errorToShow: string;
  nextButtonClicked = false;
  formattedDOB: string = ''; 
  showTextBoxs: boolean;

  constructor(
    private rootFormGroup: FormGroupDirective,
    private formservice: FormService ,
    private fb: FormBuilder,
    private routes:Router
  ) {

}
MotherEthnicityChange(event: any) {
  this.MotherEthnicity = event.value === 'Other';

  // If the input field is not shown, clear the value
  if (!this.MotherEthnicity) {
    this.stepForm.get('Mothers_Ethnicity_If_Other').setValue('');
  }
}
FatherChange(event: any) {
  this.FatherEthnicity = event.value === 'Other';

  // If the input field is not shown, clear the value
  if (!this.FatherEthnicity) {
    this.stepForm.get('Fathers_Ethnicity_If_Other').setValue('');
  }
}
onRadioChange(event: any) {
  this.showTextBox = event.value === 'Other';

  // If the input field is not shown, clear the value
  if (!this.showTextBox) {
    this.stepForm.get('If_Other').setValue('');
  }
}
onRadioChangeblood(event: any) {
  this.showTextBoxs = event.value === 'Other';

  // If the input field is not shown, clear the value
  if (!this.showTextBoxs) {
    this.stepForm.get('BloodGroup_Other').setValue('');
  }
}
onProfilePictureSelecte(event: any) {
  const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      this.selectedImageName = fileInput.files[0].name;
    }
  const file: File = event.target.files[0];

  if (file) {
    // Check if the file type is an image
    if (!file.type.startsWith('image/')) {
      this.fileTypeError = true;
      this.errorMessage = 'Invalid file type. Please select an image.';
      return;
    }

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const img = new Image();
      img.onload = () => {

          // Image size is valid, proceed with the selected image
          this.selectedUserProfile = file;
          this.Upload_Your_Sign = file;
          this.fileTypeError = false;
          this.errorMessage = ''; // Clear any previous error message
          this.selectedImage = e.target.result;
          this.studentPhotoImageUrl = e.target.result;

      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  } else {
    this.fileTypeError = true;
    this.errorMessage = 'Please select a file.';
    this.selectedImage = '/assets/images/profilepicture.png';
    this.studentPhotoImageUrl = '/assets/images/profilepicture.png';
  }
}

isInputInvalid(controlName: string): boolean {
  const control = this.stepForm.get(controlName);
  return control?.invalid && control?.touched;
}
onNextButtonClick() {
  this.nextButtonClicked = true;
  if (!this.selectedImageName) {
    return; // Prevent navigation if image is not selected
  }

  // this.onNext();
}



uploadButtonClick() {
  
  const fileInput = document.getElementById('digitalsigninput') as HTMLInputElement;
  fileInput.click();
}
formatDate(dateString: string): string {
  const dateParts = dateString.split('-'); // Assuming date is in 'YYYY-MM-DD' format
  const year = dateParts[0];
  const month = dateParts[1];
  const day = dateParts[2];
  const monthsArray = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const monthName = monthsArray[parseInt(month, 10) - 1];
  return `${day}-${monthName}-${year}`;
}
  ngOnInit(): void {

    this.stepForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.userId = localStorage.getItem('userId');


    // Fetch additional information based on userId
    this.formservice.InfoseekMasterId(this.userId).subscribe(
      (data: any) => {
        const InfoseekMasterId = data.Result[0];
        const formattedDOB = this.formatDate(InfoseekMasterId['Student_DOB']);
        console.log(formattedDOB,"const formattedDOB")

        this.studentPhotoImageUrl = InfoseekMasterId['upload_photo'];
        console.log(this.studentPhotoImageUrl,"this.studentPhotoImageUrl")
        

        if (this.studentPhotoImageUrl) {
          const filename = new URL(this.studentPhotoImageUrl).pathname.split('/').pop();
          this.studentPhotofilename = filename; // Store filename for display
          console.log( this.studentPhotofilename,"image name@@@");
        } else {
          console.warn('studentPhotoImageUrl is null or undefined.');
          this.studentPhotofilename = ''; // Clear placeholder if URL is unavailable
        }
        
        this.stepForm.patchValue({
        following_information_providedby :InfoseekMasterId['following_information_providedby'],
        If_Other:InfoseekMasterId['If_Other'],
        Student_FirstName:InfoseekMasterId['Student_FirstName'],
        Student_middelname1: InfoseekMasterId['Student_MiddleName1'],
        Student_middelname2: InfoseekMasterId['Student_MiddleName2'],
        Student_lastname : InfoseekMasterId['Student_LastName'],
        Student_DOB : formattedDOB,
        Gender : InfoseekMasterId['Gender'],
        BloodGroup :InfoseekMasterId['BloodGroup'],
        Rh_Factor : InfoseekMasterId['Rh_Factor'],
        Number_Of_Sisters : InfoseekMasterId['Number_Of_Sisters'],
        Number_Of_Brothers : InfoseekMasterId['Number_Of_Brothers'],
        Mothers_FirstName : InfoseekMasterId['Mothers_FirstName'],
        Mothers_MiddleName1 : InfoseekMasterId['Mothers_MiddleName1'],
        Mothers_MiddleName2 : InfoseekMasterId['Mothers_MiddleName2'],
        Mothers_LastName : InfoseekMasterId['Mothers_LastName'],
        Mothers_Ethnicity : InfoseekMasterId['Mothers_Ethnicity'],
        Mothers_Ethnicity_If_Other : InfoseekMasterId['Mothers_Ethnicity_If_Other'],
        Fathers_FirstName :InfoseekMasterId['Fathers_FirstName'],
        Fathers_MiddleName1 : InfoseekMasterId['Fathers_MiddleName1'],
        Fathers_MiddleName2 : InfoseekMasterId['Fathers_MiddleName2'],
        Fathers_LastName  :InfoseekMasterId['Fathers_LastName'],
        Fathers_Ethnicity : InfoseekMasterId['Fathers_Ethnicity'],
        Fathers_Ethnicity_If_Other  :InfoseekMasterId['Fathers_Ethnicity_If_Other'],
        BloodGroup_Other  :InfoseekMasterId['BloodGroup_Other'],





        })
        console.log("Form Values After Patching:", this.stepForm.value);
      },
      (error: any) => {
        console.error('Error fetching InfoseekMasterId:', error);
      }
    );


    this.stepForm = this.fb.group({
      following_information_providedby: ['', Validators.required],
      If_Other:['',Validators.maxLength(100)],
      Student_FirstName: ['', Validators.required],
      Student_middelname1: ['', Validators.required],
      Student_middelname2: ['', Validators.required],
      Student_lastname: ['', Validators.required],
      Student_DOB: ['', Validators.required],
      Gender: ['', Validators.required],
      BloodGroup: ['', Validators.required],
      Rh_Factor: ['', Validators.required],
      Number_Of_Sisters: ['', Validators.required],
      Number_Of_Brothers: ['', Validators.required],
      Mothers_FirstName: ['', Validators.required],
      Mothers_MiddleName1: ['', Validators.required],
      Mothers_MiddleName2: ['', Validators.required],
      Mothers_LastName: ['', Validators.required],
      Mothers_Ethnicity: ['', Validators.required],
      Mothers_Ethnicity_If_Other:['',Validators.required],
      Fathers_FirstName: ['', Validators.required],
      Fathers_MiddleName1: ['', Validators.required],
      Fathers_MiddleName2: ['', Validators.required],
      Fathers_LastName: ['', Validators.required],
      Fathers_Ethnicity: ['', Validators.required],
      Fathers_Ethnicity_If_Other:['',Validators.required],
      BloodGroup_Other:['',Validators.required],




    });
  }

  onSubmit() {
    const dobValue = this.stepForm.get('Student_DOB')?.value || null;
    if (dobValue) {
      const dobDate = new Date(dobValue);
      const dd = String(dobDate.getDate()).padStart(2, '0');
      const mm = String(dobDate.getMonth() + 1).padStart(2, '0'); // January is 0!
      const yyyy = dobDate.getFullYear();
      const formattedDOB = `${yyyy}-${mm}-${dd}`;
      if (this.stepForm.get('following_information_providedby')?.valid && this.stepForm.get('Student_FirstName')?.valid && this.stepForm.get('Student_lastname')?.valid && this.stepForm.get('Gender')?.valid && this.stepForm.get('BloodGroup')?.valid && this.stepForm.get('Rh_Factor')?.valid && this.stepForm.get('Mothers_FirstName')?.valid && this.stepForm.get('Mothers_LastName')?.valid && this.stepForm.get('Mothers_Ethnicity')?.valid && this.stepForm.get('Fathers_FirstName')?.valid && this.stepForm.get('Fathers_LastName')?.valid && this.stepForm.get('Fathers_Ethnicity')?.valid )  {

      const sectionA={
        following_information_providedby:this.stepForm.get('following_information_providedby')?.value || null,
        If_Other:this.stepForm.get('If_Other')?.value|| null,
        Student_FirstName:this.stepForm.get('Student_FirstName')?.value|| null,
        Student_middelname1:this.stepForm.get('Student_middelname1')?.value || null,
        Student_middelname2:this.stepForm.get('Student_middelname2')?.value || null,
        Student_lastname:this.stepForm.get('Student_lastname')?.value || null,
        Student_DOB:formattedDOB,
        Gender:this.stepForm.get('Gender')?.value || null,
        BloodGroup:this.stepForm.get('BloodGroup')?.value || null,
        BloodGroup_Other:this.stepForm.get('BloodGroup_Other')?.value || null,
        Rh_Factor:this.stepForm.get('Rh_Factor')?.value || null,
        Number_Of_Sisters:this.stepForm.get('Number_Of_Sisters')?.value || null,
        Number_Of_Brothers:this.stepForm.get('Number_Of_Brothers')?.value || null,
        Mothers_FirstName:this.stepForm.get('Mothers_FirstName')?.value || null,
        Mothers_MiddleName1:this.stepForm.get('Mothers_MiddleName1')?.value || null,
        Mothers_MiddleName2:this.stepForm.get('Mothers_MiddleName2')?.value || null,
        Mothers_LastName:this.stepForm.get('Mothers_LastName')?.value || null,
        Mothers_Ethnicity:this.stepForm.get('Mothers_Ethnicity')?.value || null,
        Mothers_Ethnicity_If_Other: this.stepForm.get('Mothers_Ethnicity_If_Other')?.value || null,
        Fathers_FirstName:this.stepForm.get('Fathers_FirstName')?.value || null,
        Fathers_MiddleName1:this.stepForm.get('Fathers_MiddleName1')?.value || null,
        Fathers_MiddleName2:this.stepForm.get('Fathers_MiddleName2')?.value || null,
        Fathers_LastName:this.stepForm.get('Fathers_LastName')?.value || null,
        Fathers_Ethnicity:this.stepForm.get('Fathers_Ethnicity')?.value || null,
        Fathers_Ethnicity_If_Other:this.stepForm.get('Fathers_Ethnicity_If_Other')?.value || null,

    }
  
    console.log(sectionA,"sectionA")

    const userId = localStorage.getItem('userId');
    const formData = new FormData();

    // Append form values to formData

    this.formservice.updateUserRegA(userId, sectionA).subscribe(
      (data: any) => {
        if (data['Status'] === 200) {
          console.log('User data updated successfully');
          // this.routes.navigate(['/steptwo'])
          this.studentpicUpd();

        }
      },
      (error) => {
        console.error('Error updating user data:', error);
      }
    );
  }

}
  }
  
  
studentpicUpd() {
  console.log("student pic upload");
  const userId = localStorage.getItem('userId');
  if (!userId) {
    console.log('userId not found in local storage.');
    return;
  }

  if (this.selectedUserProfile === 'null' || this.selectedUserProfile === undefined) {
    console.log("Image not provided, using filename:", this.studentPhotofilename);

    if (!this.studentPhotofilename || this.studentPhotofilename === '') {
      this.picerror = 'Pls choose the Image';
    } else {
      // Redirect to sectionB
              //  this.routes.navigate(['/steptwo'])
              this.nextButtonClick();

    }
  }else {
    // Image is provided
    const digitalSignFormData = new FormData();
    digitalSignFormData.append('InfoseekId', userId);
    digitalSignFormData.append('upload_photo', this.selectedUserProfile);

    console.log(digitalSignFormData, "narayansss", this.selectedUserProfile);

    this.formservice.updateNote(userId, digitalSignFormData).subscribe(
      (data: any) => {
        if (data['Status'] === 200) {
          console.log("photo upload sucessfull")
                  // this.routes.navigate(['/steptwo'])
                  this.nextButtonClick();

        } else {
          console.error('Error updating note:', data);
          if (data['HasError'] && data['Message']) {
            console.error('Server error message:', data['Message']);
          }
        }
        console.log(this.message, 'mmmmmmmmm');
        console.log(data, 'dddddddddddd');
      },
      (error: any) => {
        console.error('Error updating note:', error);
        if (error.status === 400) {
          console.error('Record not found. The specified ID might be incorrect.');
        }
        // Handle the error here (e.g., show an error message to the user)
      }
    );
  }
}



  selectedTab: string = '';
  checkboxModel: any = {};
  showTextBox: boolean = false;
  MotherEthnicity:boolean=false;
  FatherEthnicity:boolean=false;

  showOptions(divNumber: number): void {
    this.showTextBox = divNumber === 2;
    this.checkboxModel = {};
  }

  nextButtonClick(): void {
    this.formservice.incrementActiveStep();
  }

}
